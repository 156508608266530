<template>
	<v-container fill-height fluid>
		<v-row>
			<v-col cols="12" align="center">
				<v-card flat color="backgroundColor" width="800px">
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-img
							     	height="200"
							     	contain
							    	:src="'../img/cp-full-logo.png'"
							    ></v-img>
							</v-col>
							<v-col cols="12" align="center">
								<v-container fluid>
									<video :width="videoWidth" controls class="mx-auto">
										<source :src="'../video/welcome.mp4'" type="video/mp4">
									</video>
								</v-container>
							</v-col>
							<v-col cols="12" sm="12" md="6" align="center">
								<v-dialog 
									width="500px"
									persistent
									v-model="returningClientDialog">
									<v-card width="600px" class="backgroundColor pt-3">
										<v-container class="px-5">
											<v-row>
												<v-col cols="12">
													<v-text-field label="SSN" outlined background-color="white" v-on:keypress="numberOnly" v-model="ssn" maxlength="11"></v-text-field>
												</v-col>
												<v-col cols="12">
													<v-dialog
														ref="dialog"
														v-model="datepickerDialog"
														:return-value.sync="birthdate"
														persistent
														width="290px">
														<template v-slot:activator="{ on, attrs }">
															<v-text-field 
																label="DOB" 
																outlined 
																background-color="white"
																v-bind="attrs"
		            											v-on="on"
		            											v-model="birthdate"
		            											append-icon="mdi-calendar"
		            											readonly></v-text-field>
	            										</template>
	            										<v-date-picker
															v-model="birthdatePicker"
															scrollable>
															<v-spacer></v-spacer>
															<v-tooltip top>
																<template v-slot:activator="{on, attrs}">
																	<div v-bind="attrs" v-on="on">
																		<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
																	</div>
																</template>
																<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
																</span>
															</v-tooltip>
															<v-spacer></v-spacer>
															<v-btn 
																text	
																color="primary"
																@click="datepickerDialog = false">
																Cancel
															</v-btn>
															<v-btn
																text
																color="primary"
																@click="$refs.dialog.save(birthdate)">
																OK
															</v-btn>
														</v-date-picker>
	            									</v-dialog>
												</v-col>
												<v-col cols="12" sm="12" md="6" align="center">
													<v-btn outlined block color="primary" @click="returningClientDialog = false">Cancel</v-btn>
												</v-col>
												<v-spacer></v-spacer>
												<v-col cols="12" sm="12" md="6" align="center">
													<v-btn class="primary" block @click="fetchTaxPayerInfo">Submit</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</v-card>
									<template v-slot:activator="{ on, attrs}">
										<v-btn width="250" :block="$vuetify.breakpoint.smAndDown" color="primary" v-bind="attrs" v-on="on">Returning Client</v-btn>
									</template>
								</v-dialog>
							</v-col>
							<v-col cols="12" sm="12" md="6" align="center">
								<v-btn width="250" :block="$vuetify.breakpoint.smAndDown" color="primary" :to="'/tax-organizer'">New Client</v-btn>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<span class="text-caption grey--text"><v-icon color="grey">mdi-information-outline</v-icon> For previous clients or continue answering survey</span>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
		<Loading v-if="isLoading" />
	</v-container>
</template>
<script>
	import dayjs from 'dayjs'
	export default{
		data: () => ({
			returningClientDialog: false,
			datepickerDialog: false,
			birthdatePicker: '',
			birthdate: '',
			ssn: '',
			isLoading: false
		}),
		computed: {
			videoWidth(){
				if(this.$vuetify.breakpoint.mdAndUp){
					return '650px'
				}
				else{
					return '100%'
				}
			}
		},
		methods: {
			async fetchTaxPayerInfo(){
				this.isLoading = true
				await this.$store.dispatch('getTaxPayerInfo', {
					ssn: this.ssn,
					dob: this.birthdatePicker
				})
				this.isLoading = false
				this.$router.push('/tax-organizer')
			}
		},
		watch: {
			birthdatePicker(date){
				this.birthdate = dayjs(date).format('MM/DD/YYYY')
			},
			ssn(val){
				if(val.length == 3 || val.length == 6){
					this.ssn = `${val}-`
				}
			}
		}	
	}
</script>