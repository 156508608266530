import Vue from 'vue'

Vue.mixin({
	data: () => ({
		
	}),
	methods: {
		numberOnly(e){
			if(!(/^[0-9]*$/.test(e.key))){
				e.preventDefault()
			}
		},
		priceOnly(e){
			if(!(/^[0-9.]*$/.test(e.key))){
				e.preventDefault()
			}
		}
	}
})

export default {

}