<template>
    <v-app>
        <v-main class="backgroundColor">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
    	computed: {
			...mapState({
				isReturningClient: state => state.isReturningClient
			})
		},
        methods: {
            async getPricing(){
                await this.$store.dispatch('getPricing', true)
            }
        },
       	mounted(){
       		if(this.isReturningClient == false){
				this.getPricing() 
			}
       	} 
    }
</script>
