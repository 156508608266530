export default {
	state: {
		generalInfoForm: []
	},
	mutations: {
		SET_GENERAL_INFO_FORM(state, genInfo){
			state.generalInfoForm = genInfo
		}
	},
	getters: {

	},
	actions: {
		setGeneralInfoForm({commit}, genInfo){
			commit('SET_GENERAL_INFO_FORM', genInfo)
		}
	}
}