export default {
	state: {
		optionsForm: []
	},
	mutations: {
		SET_OPTIONS_FORM(state, options){
			state.optionsForm = options
		}
	},
	getters: {

	},
	actions: {
		setOptionsForm({commit}, options){
			commit('SET_OPTIONS_FORM', options)
		}
	}
}