import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
	theme: {
		themes: {
			light: {
				primary: '#A14F3D',
				backgroundColor: '#FFE9E4',
				disabled: '#B1B1B1'
			}
		}
	}
}

export default new Vuetify(opts)