<template>
	<v-container fluid>
		<v-form ref="PersonalDeductions">
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">1. How much did you pay in for health insurance during the tax year? (Remember to include amounts deducted from your paychecks as well)</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="amountRange"
						item-text="text"
						item-value="id"
						v-model="personalDeductions.q1"
						label="Amount"
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">2. How much did you pay for all other medical expenses for you, your spouse, and all dependents that you were not reimbursed for? (please include surgeries, prescription medications, copays, dental and vision expenses, etc. Do not include over-the-counter medications such as Tylenol)</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="amountRange"
						item-text="text"
						item-value="id"
						v-model="personalDeductions.q2"
						label="Amount"
					    :rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">3. Did you pay real estate taxes on your main or secondary residence separate from your mortgage payment?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q3" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q3 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="personalDeductions.q3_amount" label="Amount" prefix="$" outlined background-color="white" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">4. Did you pay any personal property tax on vehicles you own or other personal equipment?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q4" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q4 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="personalDeductions.q4_amount" label="Amount" prefix="$" outlined background-color="white" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">5. Did you pay mortgage interest on a home loan?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q5" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q5 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="personalDeductions.q5_amount" label="Amount" prefix="$" outlined background-color="white" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">6. Did you pay interest on a student loan?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q6" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q6 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="personalDeductions.q6_amount" label="Amount" prefix="$" outlined background-color="white" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">7. Did you contribute any money, property, clothing, or equipment to any charities or religious organizations?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q7" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q7 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12">
							<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="personalDeductions.q7_amount" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">
						<v-tooltip top>
							<template v-slot:activator="{on, attrs}">
								<div v-bind="attrs" v-on="on">
									8. Are you a teacher or an educator? <v-icon>mdi-information-outline</v-icon>
								</div>
							</template>
							<span>A Teacher or Educator must meet the following criteria
								<ul>
									<li>Work as a teacher, instructor, counselor, principal or classroom aide</li>
									<li>Work with students in kindergarten through grade 12</li>
									<li>Complete at least 900 hours of work during the school year</li>
									<li>Be employed by a school that provides elementary or secondary education as determined by state law</li>
								</ul>
							</span>
						</v-tooltip>
					</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q8" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">9. If you have dependents (children or aging relatives that qualify as your dependent), did you pay for someone to care for them while you were away work?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q9" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q9 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="How much was paid to the provider" outlined background-color="white" v-model="personalDeductions.q9_amount" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Childcare provider's name" outlined background-color="white" v-model="personalDeductions.q9_name" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Childcare provider's address" outlined background-color="white" v-model="personalDeductions.q9_address" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Childcare provider's social security number" outlined background-color="white" v-model="personalDeductions.q9_ssn" maxlength="11"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Childcare provider's tax idenfication number" outlined background-color="white" v-model="personalDeductions.q9_tin" maxlength="11"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-alert color="primary lighten-2" class="white--text" dense><v-icon color="white">mdi-information-outline</v-icon> Provide either SSN or TIN</v-alert>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">10. Did you or any dependents attend and pay for university courses during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q10" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="personalDeductions.q10 == 1" class="mb-8">
				<v-col cols="12">
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Student's name" outlined background-color="white" v-model="personalDeductions.q10_name" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Student's SSN" outlined background-color="white" v-model="personalDeductions.q10_ssn" :rules="requiredRule" maxlength="11"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Total tuition paid" outlined background-color="white" v-model="personalDeductions.q10_tuition" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Total books and equipment paid" outlined background-color="white" v-model="personalDeductions.q10_equipment" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Total room & board paid" outlined background-color="white" v-model="personalDeductions.q10_room" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Name of the university or college" outlined background-color="white" v-model="personalDeductions.q10_univ_name" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Address of the university or college" outlined background-color="white" v-model="personalDeductions.q10_univ_address" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="EIN of the university or college" outlined background-color="white" v-model="personalDeductions.q10_univ_ein" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">11. To your knowledge, has the American Opportunity Credit (formerly known as the HOPE credit) been claimed for this dependent in the past?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q11" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I don't know"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">12. Were any scholarships or grants received for this dependent for tuition to attend a university or college?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="personalDeductions.q12" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I don't know"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<script>
	import dayjs from 'dayjs'
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			personalDeductions: {
				q1: '',
				q2: '',
				q3: '',
				q3_amount: '',
				q4: '',
				q4_amount: '',
				q5: '',
				q5_amount: '',
				q6: '',
				q6_amount: '',
				q7: '',
				q7_amount: '',
				q8: '',
				q9: '',
				q9_amount: '',
				q9_name: '',
				q9_address: '',
				q9_ssn: '',
				q9_tin: '',
				q10: '',
				q10_name: '',
				q10_ssn: '',
				q10_tuition: '',
				q10_equipment: '',
				q10_room: '',
				q10_univ_name: '',
				q10_univ_address: '',
				q10_univ_ein: '',
				q11: '',
				q12: ''
			},
			amountRange: [
				{
					id: '1',
					text: 'Less than $1,000'
				},
				{
					id: '2',
					text: '$1,001-$2,000'
				},
				{
					id: '3',
					text: '$2,001-$3,000'
				},
				{
					id: '4',
					text: 'More than $4,000'
				}
			],
			q3DatepickerDialog: false,
			requiredRule: [v => !!v || 'Required']
		}),
		computed: {
			...mapState({
				isReturningClient: state => state.isReturningClient
			})
		},
		watch: {
			'personalDeductions.q3'(val){
				this.$store.dispatch('setUploadList', {
					id: 12,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q4'(val){
				this.$store.dispatch('setUploadList', {
					id: 13,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q5'(val){
				this.$store.dispatch('setUploadList', {
					id: 14,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q6'(val){
				this.$store.dispatch('setUploadList', {
					id: 15,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q7'(val){
				this.$store.dispatch('setUploadList', {
					id: 16,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q9'(val){
				this.$store.dispatch('setPayment', {
					section: 4,
					question: 9,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q9_ssn'(val){
				if(val != '' && val != null){
					this.personalDeductions.q9_tin = ''
				}
				if(val != null){
					if(val.length == 3 || val.length == 6){
						this.personalDeductions.q9_ssn = `${val}-`
					}
				}
			},
			'personalDeductions.q9_tin'(val){
				if(val != '' && val != null){
					this.personalDeductions.q9_ssn = ''
				}
				if(val != null){
					if(val.length == 2){
						this.personalDeductions.q9_tin = `${val}-`
					}
				}
			},
			'personalDeductions.q10'(val){
				this.$store.dispatch('setPayment', {
					section: 4,
					question: 10,
					active: val == 1 ? true : false
				})

				this.$store.dispatch('setUploadList', {
					id: 17,
					active: val == 1 ? true : false
				})
			},
			'personalDeductions.q10_ssn'(val){
				if(val != null){
					if(val.length == 3 || val.length == 6){
						this.personalDeductions.q10_ssn = `${val}-`
					}
				}
			}
		},
		methods: {
			storeForm(){
				this.$store.dispatch('setPersonalDeductionsForm', this.personalDeductions)
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData' && this.$store.state.personalDeductionsModule.personalDeductionsForm.length != 0){
				this.personalDeductions = this.$store.state.personalDeductionsModule.personalDeductionsForm
			}
		},
		deactivated(){
			this.storeForm()
		}
	}
</script>