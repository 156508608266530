<template>
	<v-container fluid>
		<v-form ref="Options">
			<v-row class="mb-8">
				<v-col cols="12" align="center">
					<video :width="videoWidth" controls class="mx-0">
						<source :src="'../video/pricing.mp4'" type="video/mp4">
					</video>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">1. Who would you like to prepare your return?</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="returnOptions"
						item-text="text"
						item-value="id"
						v-model="options.q1"
						label=Option
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">2. Who would you like to review your return?</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="reviewOptions.slice(reviewOptionSlice)"
						item-text="text"
						item-value="id"
						v-model="options.q2"
						label="Option"
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">3. Do you need us to file a state return? How many?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="options.q3" :rules="requiredRule">
						<v-radio
							label="Yes, one state return"
							value="1"
						></v-radio>
						<v-radio
							label="Yes, two state returns"
							value="2"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">4. Counting from when we receive final documents and information from you, how soon do you need your tax return to be completed?</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="businessDays"
						item-text="text"
						item-value="id"
						v-model="options.q4"
						label=Option
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">5. Would you like to have an electronic copy of your tax return or would you prefer a copy to be mailed at to you?</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="copyOptions"
						item-text="text"
						item-value="id"
						v-model="options.q5"
						label=Option
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">6. Would you like to settle your tax preparation costs using your bank account, credit card, or from your anticipated tax refund?</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="taxCostSettlement"
						item-text="text"
						item-value="id"
						v-model="options.q6"
						label=Option
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">7. We now offer tax resolution services for those that receive correspondence from the IRS or state regarding a variety of matters. With this service, CP will review the correspondence for you and prepare responses in your behalf, whether it be research, drafting a letter, making phone calls to the IRS or state, or other resolution matters. This service is for a small monthly recurring charge of $10.40. The cost for this service will be billed separately from the cost of your tax return preparation. Would you like this service?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="options.q7" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I would like to talk to someone before making a decision"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">When you load your documents to us, we expect the following documents to be included:</div>
				</v-col>
				<v-col cols="12">
					<v-list color="backgroundColor">
						<v-list-item v-for="(list, index) in options.list" :key="`${index}-upload`">
							<v-checkbox v-model="options.list[index].checked" :label="options.list[index].document"></v-checkbox>
						</v-list-item>
					</v-list>
				</v-col>
				<!-- <v-col cols="12" sm="12" md="12">
					<v-file-input
						v-model="options.uploads"
						label="Upload your documents here"
						accept="image/*,application/pdf,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						hint="Allowed file types: images, docx, xlsx, pdf"
						multiple
						type="file"
						outlined background-color="white"
						:rules="fileSizeRule"></v-file-input>
				</v-col> -->
				<v-col cols="12" sm="12" md="12">
					<v-btn class="primary" target="_blank" href="https://cpennies.securefilepro.com/portal/#/login">Upload Documents Here</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<script>
	import { mapState, mapGetters } from 'vuex'
	export default{
		data: () => ({
			options: {
				q1: '',
				q2: '',
				q3: '',
				q4: '',
				q5: '',
				q6: '',
				q7: '',
				list: []
			},
			returnOptions: [
				{
					id: '1',
					text: 'Tax Accountant'
				},
				{
					id: '2',
					text: 'Senior Tax Accountant'
				},
				{
					id: '3',
					text: 'CPA Enrolled Agent'
				},
			],
			reviewOptions: [
				{
					id: '1',
					text: 'Senior Tax Accountant'
				},
				{
					id: '2',
					text: 'CPA Enrolled Agent'
				},
			],
			businessDays: [
				{
					id: '1',
					text: '3-5 business days'
				},
				{
					id: '2',
					text: '6-10 business days'
				},
				{
					id: '3',
					text: '11-14 business days'
				}
			],
			taxCostSettlement: [
				{
					id: '1',
					text: 'Bank Account'
				},
				{
					id: '2',
					text: 'Credit Card'
				},
				{
					id: '3',
					text: 'Tax Refund'
				}
			],
			copyOptions: [
				{
					id: '1',
					text: 'Electronic Copy'
				},
				{
					id: '2',
					text: 'Mail me a copy'
				}
			],
			reviewOptionSlice: 0,
			requiredRule: [v => !!v || 'Required'],fileSizeRule: [v => {
				if(v){
					return !v.some(file => file.size > 2e6) || 'Should be less than 2 MB per file'
				}
				else{
					return true
				}
			}]
		}),
		computed: {
			...mapState({
				basePrice: state => state.basePrice,
				isReturningClient: state => state.isReturningClient
			}),
			...mapGetters([
				'uploadDocumentList'
			]),
			videoWidth(){
				if(this.$vuetify.breakpoint.mdAndUp){
					return '750px'
				}
				else{
					return '100%'
				}
			}
		},
		watch: {
			'options.q1'(answer){
				if(answer == 1 || answer == 2){ // tax account and senior tax accountant
					this.reviewOptionSlice = 0
				}
				else if(answer == 3){ //cpa enrolled agent
					this.reviewOptionSlice = 1
				}
				for(let i = 0; this.returnOptions.length > i; i++){
					this.$store.dispatch('setPayment', {
						section: 8,
						question: 1,
						answer: this.returnOptions[i].id,
						active: this.returnOptions[i].id == answer ? true : false
					})
				}
			},
			'options.q2'(answer){
				for(let i = 0; this.reviewOptions.length > i; i++){
					this.$store.dispatch('setPayment', {
						section: 8,
						question: 2,
						answer: this.reviewOptions[i].id,
						active: this.reviewOptions[i].id == answer ? true : false
					})
				}
			},
			'options.q3'(answer){
				let answers = [1, 2, 0]
				for(let i = 0; answers.length > i; i++){
					this.$store.dispatch('setPayment', {
						section: 8,
						question: 3,
						answer: answers[i],
						active: answers[i] == answer ? true : false
					})
				}
			},
			'options.q4'(answer){
				for(let i = 0; this.businessDays.length > i; i++){
					this.$store.dispatch('setPayment', {
						section: 8,
						question: 4,
						answer: this.businessDays[i].id,
						active: this.businessDays[i].id == answer ? true : false
					})
				}
			},
			'options.q5'(answer){
				for(let i = 0; this.copyOptions.length > i; i++){
					this.$store.dispatch('setPayment', {
						section: 8,
						question: 5,
						answer: this.copyOptions[i].id,
						active: this.copyOptions[i].id == answer ? true : false
					})
				}
			},
			'options.q6'(answer){
				for(let i = 0; this.taxCostSettlement.length > i; i++){
					this.$store.dispatch('setPayment', {
						section: 8,
						question: 6,
						answer: this.taxCostSettlement[i].id,
						active: this.taxCostSettlement[i].id == answer ? true : false
					})
				}
			},
			'options': {
				deep: true,
				handler(){
					this.storeForm()
				}
			}
		},
		methods: {
			storeForm(){
				this.$store.dispatch('setOptionsForm', this.options)
			},
			setDefaultOptions(){
				//to trigger watch and execute payment
				this.options.q1 = '2'
				this.options.q4 = '2'
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData' && this.$store.state.optionsModule.optionsForm.length != 0){
				this.options = this.$store.state.optionsModule.optionsForm
			}
			else{
				this.options.list = this.uploadDocumentList
				this.options.list.map(x => {
					delete x.id
					delete x.active
				})
			}
			this.setDefaultOptions()
		}
	}
</script>