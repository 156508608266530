<template>
	<v-container fluid>
		<v-form ref="BusinessDeductions">
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">1. Did you receive any business income that you did not already report on this form?</div>
				</v-col>
				<v-radio-group row v-model="businessDeductions.q1" :rules="requiredRule">
					<v-radio
						label="Yes"
						value="1"
					></v-radio>
					<v-radio
						label="No"
						value="0"
					></v-radio>
				</v-radio-group>
			</v-row>
			<template v-if="businessDeductions.q1 == 1">
				<v-row v-for="(val, index) in businessDeductions.q1_source" :key="`${index}-q1`">
					<v-col cols="6">
						<v-text-field v-model="businessDeductions.q1_source[index].source" label="Source of Income" outlined background-color="white" :rules="requiredRule"></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-model="businessDeductions.q1_source[index].amount"label="Amount" prefix="$" outlined background-color="white" append-outer-icon="mdi-plus" @click:append-outer="addField('q1_source')" v-if="businessDeductions.q1_source.length == index+1" :rules="requiredRule"></v-text-field>
						<v-text-field v-model="businessDeductions.q1_source[index].amount"label="Amount" prefix="$" outlined background-color="white" append-outer-icon="mdi-minus" @click:append-outer="removeField('q1_source', index)" v-else :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
				<v-row no-gutters class="mb-8">
					<v-col cols="12">
						<div class="text-subtitle-1">Did you process any refunds?</div>
					</v-col>
					<v-col cols="12">
						<v-radio-group row v-model="businessDeductions.q1_1" :rules="requiredRule">
							<v-radio
								label="Yes"
								value="1"
							></v-radio>
							<v-radio
								label="No"
								value="0"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row v-if="businessDeductions.q1_1 == 1" class="mb-8">
					<v-col cols="12">
						<v-text-field v-model="businessDeductions.q1_amount" label="Amount" prefix="$" outlined background-color="white" :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">2. What was your total business sales revenue before business expenses and deductions?</div>
				</v-col>
				<v-col cols="12">
					<v-select
						outlined background-color="white"
						:items="amountRange"
						item-text="text"
						item-value="id"
						v-model="businessDeductions.q2"
						label="Amount"
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">3. Please describe your business below</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="businessDeductions.q3" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="businessDeductions.q3 == '1'" v-for="(expenses, idx) in businessDeductions.q3_expenses">
				<v-row>
					<v-col cols="12" md="6" sm="12">
						<v-text-field v-model="businessDeductions.q3_business_name[idx]" label="Business Name" outlined background-color="white" :rules="requiredRule"></v-text-field>	
					</v-col>
					<v-col cols="12" md="6" sm="12">
						<v-text-field v-model="businessDeductions.q3_industry[idx]" label="Industry or Type of Business" outlined background-color="white" :rules="requiredRule"></v-text-field>
					</v-col>
					<v-col cols="12" md="6" sm="12">
						<v-text-field v-model="businessDeductions.q3_ein[idx]" label="Employer ID number (if issued one)" outlined background-color="white"></v-text-field>
					</v-col>
					<v-col cols="12" md="6" sm="12">
						<v-text-field v-model="businessDeductions.q3_total_sales[idx]" label="Total Sales" outlined background-color="white" :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="businessDeductions.q3 == '1'" v-for="(item, index) in expenses" :key="`${idx}-${index}-q3`">
					<v-col cols="4">
						<v-text-field
							outlined
							background-color="white"
							v-model="businessDeductions.q3_expenses[idx][index].expense"
							:disabled="(!businessDeductions.q3_expenses[idx][index].hasOwnProperty('is_editable'))"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<template v-if="businessDeductions.q3_expenses[idx][index].hasOwnProperty('is_editable')">
							<v-text-field
								prefix="$"
								label="Amount"
								outlined
								background-color="white"
								v-model="businessDeductions.q3_expenses[idx][index].amount"
								append-outer-icon="mdi-plus"
								@click:append-outer="addOtherExpense(idx)"
								v-if="otherExpensesLength[idx].length == index-20"
							></v-text-field>
							<v-text-field
								prefix="$"
								label="Amount"
								outlined
								background-color="white"
								v-model="businessDeductions.q3_expenses[idx][index].amount"
								append-outer-icon="mdi-minus"
								@click:append-outer="removeOtherExpense(idx, index)"
								v-else
							></v-text-field>
						</template>
						<template v-else>
							<v-text-field
								prefix="$"
								label="Amount"
								outlined
								background-color="white"
								v-model="businessDeductions.q3_expenses[idx][index].amount"
							></v-text-field>
						</template>
					</v-col>
					<v-col cols="4">
						<span v-if="index == 0" class="mb-0">Has supporting documents?</span>
						<v-radio-group row v-model="businessDeductions.q3_expenses[idx][index].has_supporting_docs" :class="{ 'mt-0': index == 0 }">
							<v-radio
								label="Yes"
								value="1"
							></v-radio>
							<v-radio
								label="No"
								value="0"
							></v-radio>
						</v-radio-group>
					</v-col>
					<v-col cols="12">
						<v-row v-if="businessDeductions.q3_expenses[idx][index].expense == 'Advertising'">
							<v-col cols="12" sm="12" md="6">
								<v-dialog
									ref="dateVehicleDialog"
									v-model="businessDeductions.q3_expenses[idx][index].dialog"
									:return-value.sync="vehicleDate[idx]"
									persistent
									width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field 
											label="Date vehicle placed in service for business" 
											outlined background-color="white" 
											v-model="vehicleDate[idx]"
											v-bind="attrs"
											v-on="on"
											append-icon="mdi-calendar"
											readonly
											></v-text-field>
									</template>
									<v-date-picker
										v-model="businessDeductions.q3_expenses[idx][index].vehicle_date"
										scrollable>
										<v-spacer></v-spacer>
										<v-tooltip top>
											<template v-slot:activator="{on, attrs}">
												<div v-bind="attrs" v-on="on">
													<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
												</div>
											</template>
											<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
											</span>
										</v-tooltip>
										<v-spacer></v-spacer>
										<v-btn 
											text	
											color="primary"
											@click="businessDeductions.q3_expenses[idx][index].dialog = false">
											Cancel
										</v-btn>
										<v-btn
											text
											color="primary"
											@click="$refs.dateVehicleDialog[idx].save(vehicleDate[idx])">
											OK
										</v-btn>
									</v-date-picker>
								</v-dialog>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field
									label="Business Mileage"
									outlined
									background-color="white"
									v-model="businessDeductions.q3_expenses[idx][index].business_mileage"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field
									label="Commuting miles"
									outlined
									background-color="white"
									v-model="businessDeductions.q3_expenses[idx][index].commuting_miles"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field
									label="Other miles"
									outlined
									background-color="white"
									v-model="businessDeductions.q3_expenses[idx][index].other_miles"
								></v-text-field>
							</v-col>
							<v-row no-gutters class="ml-5">
								<v-col cols="12">
									<div class="text-subtitle-1">Was vehicle available for personal use during off-duty hours?</div>
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-radio-group row v-model="businessDeductions.q3_expenses[idx][index].q1">
										<v-radio
											label="Yes"
											value="1"
										></v-radio>
										<v-radio
											label="No"
											value="0"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row no-gutters class="ml-5">
								<v-col cols="12">
									<div class="text-subtitle-1">Do you have another vehicle available for personal use?</div>
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-radio-group row v-model="businessDeductions.q3_expenses[idx][index].q2">
										<v-radio
											label="Yes"
											value="1"
										></v-radio>
										<v-radio
											label="No"
											value="0"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row no-gutters class="ml-5">
								<v-col cols="12">
									<div class="text-subtitle-1">Do you have evidence to support your deduction?</div>
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-radio-group row v-model="businessDeductions.q3_expenses[idx][index].q3">
										<v-radio
											label="Yes"
											value="1"
										></v-radio>
										<v-radio
											label="No"
											value="0"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row no-gutters class="ml-5">
								<v-col cols="12">
									<div class="text-subtitle-1">If “Yes” is the evidence written?</div>
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-radio-group row v-model="businessDeductions.q3_expenses[idx][index].q4">
										<v-radio
											label="Yes"
											value="1"
										></v-radio>
										<v-radio
											label="No"
											value="0"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
						</v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-btn color="primary" text @click="addExpenseForm" v-if="businessDeductions.q3_expenses.length == idx+1"><v-icon>mdi-plus</v-icon> Add Expense Form</v-btn>
						<v-btn color="primary" text @click="removeExpenseForm(idx)" v-else><v-icon>mdi-minus</v-icon> Remove Expense Form</v-btn>
					</v-col>
				</v-row>
				<v-divider v-if="businessDeductions.q3_expenses.length > 1" class="mb-5"></v-divider>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">4. Did you use a portion of your home regularly and exclusively for business (i.e.- a home office?)</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="businessDeductions.q4" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="businessDeductions.q4 == 1" class="mb-8">
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">What is the square footage of the entire residence?</div>
					</v-col>
					<v-col cols="12">
						<v-text-field label="Square Footage" outlined background-color="white" v-model="businessDeductions.q4_sq_ft_residence" :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">What is the square footage of the space you are using for business?</div>
					</v-col>
					<v-col cols="12">
						<v-text-field label="Square Footage" outlined background-color="white" v-model="businessDeductions.q4_sq_ft_business" :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">Is the space also used sometimes for non-business purposes?</div>
					</v-col>
					<v-col cols="12">
						<v-radio-group row v-model="businessDeductions.q4_3" :rules="requiredRule">
							<v-radio
								label="Yes"
								value="1"
							></v-radio>
							<v-radio
								label="No"
								value="0"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">If you own your home, what was the total cost of the purchase and in what year did you make the purchase?</div>
						<v-row>
							<v-col cols="12" sm="12" md="6">
								<v-text-field v-model="businessDeductions.q4_purchase_cost" label="Total cost of purchase" outlined background-color="white" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field v-model="businessDeductions.q4_year" label="Year" outlined background-color="white" :rules="requiredRule"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">Have there been any significant purchases or improvement made on the home since you initially purchased it?</div>
					</v-col>
					<v-col cols="12">
						<v-radio-group row v-model="businessDeductions.q4_6" :rules="requiredRule">
							<v-radio
								label="Yes"
								value="1"
							></v-radio>
							<v-radio
								label="No"
								value="0"
							></v-radio>
						</v-radio-group>
					</v-col>
					<v-row v-if="businessDeductions.q4_6 == 1" class="mb-8">
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Purchase or Improvement" outlined background-color="white" v-model="businessDeductions.q4_improvement" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="businessDeductions.q4_improvement_amount" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">What were the total amounts you spent during the year while you had this space available for business purposes?</div>
						<v-row>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Casualty Loss" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_casualty_loss" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Mortgage Interest" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_mortgage" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Real Estate Taxes" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_real_estate" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Insurance" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_insurance" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Rent" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_rent" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Repairs and maintenance" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_repair" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Utilities (includes internet, electricity, phone charges)" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_utilities" :rules="requiredRule"></v-text-field>
							</v-col>
						</v-row>
						<v-row v-for="(val, index) in businessDeductions.q4_other" :key="`${index}-q4_other`">
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Other expenses" outlined background-color="white" v-model="businessDeductions.q4_other[index].expense"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field :label="`${businessDeductions.q4_other[index].expense} Amount`" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_other[index].amount" append-outer-icon="mdi-plus" v-if="businessDeductions.q4_other.length == index+1" @click:append-outer="addField('q4_other')"></v-text-field>
								<v-text-field :label="`${businessDeductions.q4_other[index].expense} Amount`" outlined background-color="white" prefix="$" v-model="businessDeductions.q4_other[index].amount" append-outer-icon="mdi-minus" v-else @click:append-outer="removeField('q4_other', index)"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
		</v-form>
	</v-container>
</template>
<script>
	import dayjs from 'dayjs'
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			businessDeductions: {
				q1: '',
				q1_source: [
					{
						source: '',
						amount: ''
					}
				],
				q1_1: '',
				q1_amount: '',
				q2: '',
				q3: '',
				q3_business_name: [],
				q3_industry: [],
				q3_ein: [],
				q3_total_sales: [],
				q3_expenses: [
					[
						{
							expense: 'Advertising',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Commissions and fees',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Contract labor',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Depletion',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Depreciation',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Employee benefit programs (other than pension or profit sharing)',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Insurance',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Interest - Mortgage paid to banks',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Interest - Other',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Legal and professional services',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Office expenses',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Pension and profit-sharing plans',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Rent or lease of vehicle, machinery, and equipment',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Rent or lease of business property',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Repairs and maintenance',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Supplies (not included in Costs of Goods Sold)',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Taxes and licenses',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Travel',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Deductible meals',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Utilities',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Wages (less employment credits)',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Other Expenses',
							is_editable: true,
							amount: '',
							has_supporting_docs: ''
						},
					]
				],
				q4: '',
				q4_sq_ft_residence: '',
				q4_sq_ft_business: '',
				q4_3: '',
				q4_purchase_cost: '',
				q4_year: '',
				q4_6: '',
				q4_improvement: '',
				q4_improvement_amount: '',
				q4_casualty_loss: '',
				q4_mortgage: '',
				q4_real_estate: '',
				q4_insurance: '',
				q4_rent: '',
				q4_repair: '',
				q4_utilities: '',
				q4_other: [
					{
						expense: '',
						amount: ''
					}
				]
			},
			amountRange: [
				{
					id: '1',
					text: '$1-$50,000'
				},
				{
					id: '2',
					text: '$50,001-$100,000'
				},
				{
					id: '3',
					text: '$100,001 or more'
				}
			],
			requiredRule: [v => !!v || 'Required'],
			vehicleDate: [],
		}),
		computed: {
			otherExpensesLength(){
				let others = []
				for(let i = 0; this.businessDeductions.q3_expenses.length > i; i++){
					others[i] = this.businessDeductions.q3_expenses[i].filter(x => x.hasOwnProperty('is_editable'))
				}
				return others
			},
			...mapState({
				isReturningClient: state => state.isReturningClient
			})
		},
		watch: {
			'businessDeductions.q2'(val){
				this.$store.dispatch('setPayment', {
					section: 5,
					question: 2,
					active: val == 3 ? true : false
				})
			},
			'businessDeductions.q3'(val){
				this.$store.dispatch('setUploadList', {
					id: 18,
					active: val == 1 ? true : false
				})
			},
			'businessDeductions.q3_expenses': {
				deep: true,
				handler(){
					let documents = []
					let dates = []
					for(let i = 0; i < this.businessDeductions.q3_expenses.length; i++){
						let hasSuppDocs = this.businessDeductions.q3_expenses[i].filter(x => x.has_supporting_docs == '1')
						let expenseList = hasSuppDocs.map(x => x['expense'])
						for(let j = 0; expenseList.length > j; j++){
							if(!documents.includes(expenseList[j])){
								documents = documents.concat(expenseList[j])
							}
						}

						//date for advertising
						let hasVehicleDate = this.businessDeductions.q3_expenses[i].filter(x => x.hasOwnProperty('vehicle_date'))
						if(hasVehicleDate.length > 0){
							dates = dates.concat(hasVehicleDate[0].vehicle_date)
						}
					}

					this.$store.dispatch('setUploadList', {
						id: 20,
						document: `${documents.join(',')} supporting documents`,
						active: documents.length > 0 ? true : false
					})

					for(let k = 0; dates.length > k; k++){
						this.vehicleDate[k] = dayjs(dates[k]).format('MM/DD/YYYY')
					}
				}
			}
		},
		methods: {
			addField(field){
				this.businessDeductions[field].push({})
			},
			removeField(field, index){
				this.businessDeductions[field].splice(index, 1)
			},
			addOtherExpense(index){
				this.businessDeductions.q3_expenses[index].push({
					expense: 'Other Expenses',
					amount: '',
					is_editable: true,
					has_supporting_docs: ''
				})
			},
			removeOtherExpense(index, secondIndex){
				this.businessDeductions.q3_expenses[index].splice(secondIndex, 1)
			},
			storeForm(){
				this.businessDeductions.q3_expenses.map(x => {
					delete x[0].dialog
				})
				this.$store.dispatch('setBusinessDeductionsForm', this.businessDeductions)
			},
			addExpenseForm(){
				this.businessDeductions.q3_expenses.push([
					{
						expense: 'Advertising',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Commissions and fees',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Contract labor',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Depletion',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Depreciation',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Employee benefit programs (other than pension or profit sharing)',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Insurance',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Interest - Mortgage paid to banks',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Interest - Other',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Legal and professional services',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Office expenses',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Pension and profit-sharing plans',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Rent or lease of vehicle, machinery, and equipment',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Rent or lease of businesss property',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Repairs and maintenance',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Supplies (not included in Costs of Goods Sold)',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Taxes and licenses',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Travel',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Deductible meals',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Utilities',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Wages (less employment credits)',
						amount: '',
						has_supporting_docs: ''
					},
					{
						expense: 'Other Expenses',
						is_editable: true,
						amount: '',
						has_supporting_docs: ''
					},
				])
			},
			removeExpenseForm(index){
				this.businessDeductions.q3_expenses.splice(index, 1)
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData' && this.$store.state.businessDeductionsModule.businessDeductionsForm.length != 0){
				this.businessDeductions = this.$store.state.businessDeductionsModule.businessDeductionsForm
				if(this.businessDeductions.q4_other == null){
					this.businessDeductions.q4_other = [
						{
							expense: '',
							amount: ''
						}
					]
				}
			}
		},
		deactivated(){
			this.storeForm()
		}
	}
</script>