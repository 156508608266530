<template>
	<v-container fluid>
		<v-form ref="Income">
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">1. Did you work as an employee during the tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q1" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">2. Did you receive interest income from a bank or other financial organization?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q2" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="income.q2 == 1">
				<v-row class="mb-8" v-for="(val, index) in income.q2_info" :key="`${index}-q2`">
					<v-col cols="12">
						<v-row>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Bank/Organization" outlined background-color="white" v-model="income.q2_info[index].bank" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q2_info[index].amount" append-outer-icon="mdi-plus" @click:append-outer="addField('q2_info')" v-if="income.q2_info.length == index+1" :rules="requiredRule"></v-text-field>
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q2_info[index].amount" append-outer-icon="mdi-minus" @click:append-outer="removeField('q2_info', index)" v-else :rules="requiredRule"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">
						<v-tooltip top>
							<template v-slot:activator="{on, attrs}">
								<div v-bind="attrs" v-on="on">
									3. Did you receive dividend income from a bank or other financial organization? <v-icon>mdi-information-outline</v-icon>
								</div>
							</template>
							<span>For example, did you invest with Edward Jones, Charles Schwab, Acorns, E-Trade, Fidelity etc.</span>
						</v-tooltip>
					</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q3" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="income.q3 == 1">
				<v-row class="mb-8" v-for="(val, index) in income.q3_info" :key="`${index}-q3`">
					<v-col cols="12">
						<v-row>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Bank/Organization" outlined background-color="white" v-model="income.q3_info[index].bank" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q3_info[index].amount" append-outer-icon="mdi-plus" @click:append-outer="addField('q3_info')" v-if="income.q3_info.length == index+1" :rules="requiredRule"></v-text-field>
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q3_info[index].amount" append-outer-icon="mdi-minus" @click:append-outer="removeField('q3_info', index)" v-else :rules="requiredRule"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">
						<v-tooltip top>
							<template v-slot:activator="{on, attrs}">
								<div v-bind="attrs" v-on="on">
									4. Did you have transactions from securities or real estate that were sold or traded during the tax year?</v-icon>
								</div>
							</template>
							<span>Securities- stocks or bonds</span>
						</v-tooltip>
					</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q4" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">5. Did you receive pension income from a retirement arrangement or IRA distribution during the tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q5" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">6. Did you receive a state income tax refund last tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q6" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="income.q6 == 1">
				<v-row class="mb-8" v-for="(val, index) in income.q6_info" :key="`${index}-q6`">
					<v-col cols="12">
						<v-row>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="State" outlined background-color="white" v-model="income.q6_info[index].state" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q6_info[index].amount" append-outer-icon="mdi-plus" @click:append-outer="addField('q6_info')" v-if="income.q6_info.length == index+1" :rules="requiredRule"></v-text-field>
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q6_info[index].amount" append-outer-icon="mdi-minus" @click:append-outer="removeField('q6_info', index)" v-else :rules="requiredRule"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">7. Did you receive alimony during the tax year from a divorce that was executed on or before Dec 31, 2018?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q7" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="income.q7 == 1" class="mb-8">
				<v-col cols="12">
					<v-text-field v-model="income.q7_amount" label="Amount received" prefix="$" outlined background-color="white"></v-text-field>
				</v-col>
				<v-col cols="12" v-for="(val, index) in income.q7_ex_spouses_ssn" :key="`${index}-q7-ex`">
					<v-text-field v-model="income.q7_ex_spouses_ssn[index].ssn" label="Ex-Spouses SSN" outlined background-color="white" append-outer-icon="mdi-plus" @click:append-outer="addField('q7_ex_spouses_ssn')" v-if="income.q7_ex_spouses_ssn.length == index+1" :rules="requiredRule" maxlength="11"></v-text-field>
					<v-text-field v-model="income.q7_ex_spouses_ssn[index].ssn" label="Ex-Spouses SSN" outlined background-color="white" append-outer-icon="mdi-minus" @click:append-outer="removeField('q7_ex_spouses_ssn', index)" v-else :rules="requiredRule" maxlength="11"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">8. Did you receive federal or state unemployment compensation during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q8" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="income.q8 == 1">
				<v-row class="mb-8" v-for="(val, index) in income.q8_info" :key="`${index}-q8`">
					<v-col cols="12">
						<v-row>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="State" outlined background-color="white" v-model="income.q8_info[index].state" :rules="requiredRule"></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q8_info[index].amount" append-outer-icon="mdi-plus" @click:append-outer="addField('q8_info')" v-if="income.q8_info.length == index+1" :rules="requiredRule"></v-text-field>
								<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="income.q8_info[index].amount" append-outer-icon="mdi-minus" @click:append-outer="removeField('q8_info', index)" v-else :rules="requiredRule"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">9. Did you receive social security or railroad retirement payments during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q9" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">10. Were any debts, such as credit card debt or outstanding loans, forgiven for you during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q10" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">11. Did you receive ANY income that you did not receive a tax form for?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q11" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="income.q11 == 1">
				<v-row v-for="(val, index) in income.q11_income" :key="`${index}-q11`">
					<v-col cols="12">
						<v-select
							outlined background-color="white"
							:items="incomeOptions"
							item-text="text"
							item-value="id"
							v-model="income.q11_income[index]"
							v-if="income.q11_income.length == index+1"
							append-outer-icon="mdi-plus"
							@change="getSelectedIncome"
							@click:append-outer="addField('q11_income')"
							:rules="requiredRule"
						></v-select>
						<v-select
							outlined background-color="white"
							:items="incomeOptions"
							item-text="text"
							item-value="id"
							v-model="income.q11_income[index]"
							v-else
							append-outer-icon="mdi-minus"
							@change="getSelectedIncome"
							@click:append-outer="removeField('q11_income', index)"
							:rules="requiredRule"
						></v-select>
					</v-col>
				</v-row>
			</template>
			<v-row class="mb-8">
				<v-col cols="12">
					<div class="text-h6">Adjustments to Income:</div>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">12. Did you make any contributions to an IRA during the tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q12" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="income.q12 == 1">
				<v-row class="mb-8" v-for="(val, index) in income.q12_contrib" :key="`${index}-q12`">
					<v-col cols="12" sm="12" md="6">
						<v-select
							outlined background-color="white"
							:items="contributions"
							item-text="text"
							item-value="id"
							v-model="income.q12_contrib[index].ira"
							label="Contribution for"
							:rules="requiredRule"
						></v-select>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Amount contributed to IRA" prefix="$" outlined background-color="white" v-model="income.q12_contrib[index].amount" append-outer-icon="mdi-plus" @click:append-outer="addField('q12_contrib')" v-if="income.q12_contrib.length == index+1" :rules="requiredRule"></v-text-field>
						<v-text-field label="Amount contributed to IRA" prefix="$" outlined background-color="white" v-model="income.q12_contrib[index].amount" append-outer-icon="mdi-minus" @click:append-outer="removeField('q12_contrib', index)" v-else :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">13. Did you have any distributions from an IRA or did you pull money out of a 401k during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q13" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="income.q13 == 1" class="mb-8">
				<v-col cols="12">
					<v-row v-for="(val, index) in income.q13_1" :key="`${index}-q13`">
						<v-col cols="12">
							<v-text-field v-model="income.q13_1[index].amount" label="Amount" prefix="$" outlined background-color="white" append-outer-icon="mdi-plus" @click:append-outer="addField('q13_1')" v-if="income.q13_1.length == index+1" :rules="requiredRule"></v-text-field>
							<v-text-field v-model="income.q13_1[index].amount" label="Amount" prefix="$" outlined background-color="white" append-outer-icon="mdi-minus" @click:append-outer="removeField('q13_1', index)" v-else :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">14. Do you have carryover losses from previous years that need to be rolled over?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="income.q14" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I don't know, please check for me"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<script>
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			income: {
				q1: '',
				q2: '',
				q2_info: [
					{
						bank: '',
						amount: ''
					}
				],
				q3: '',
				q3_info: [
					{
						bank: '',
						amount: ''
					}
				],
				q4: '',
				q5: '',
				q6: '',
				q6_info: [
					{
						state: '',
						amount: ''
					}
				],
				q7: '',
				q7_ex_spouses_ssn: [
					{
						ssn: ''
					}
				],
				q8: '',
				q8_info: [
					{
						state: '',
						amount: ''
					}
				],
				q9: '',
				q10: '',
				q11: '',
				q11_income: [
					{}
				],
				q12: '',
				q12_contrib: [
					{
						ira: '',
						amount: ''
					}
				],
				q13: '',
				q13_1: [
					{
						amount: ''
					}
				],
				q14: '',
			},
			incomeOptions: [
				{
					id: 1,
					text: 'Business Income'
				},
				{
					id: 2,
					text: 'Rental Income'
				},
				{
					id: 3,
					text: 'Other Income'
				},
				{
					id: 4,
					text: 'Miscellaneous Income'
				},
				{
					id: 5,
					text: 'Foreign Earned Income'
				},
				{
					id: 6,
					text: 'Gambling Winnings'
				}
			],
			contributions: [
				{
					id: 1,
					text: 'a traditional IRA'
				},
				{
					id: 2,
					text: 'A ROTH IRA'
				},
				{
					id: 3,
					text: 'A SEP IRA'
				},
				{
					id: 4,
					text: 'Other IRA'
				},
				{
					id: 5,
					text: 'I don\'t know which kind of IRA it was'
				}
			],
			requiredRule: [v => !!v || 'Required']
		}),
		computed: {
			...mapState({
				section5Condition: state => state.section5Condition,
				section7Condition: state => state.section7Condition,
				isReturningClient: state => state.isReturningClient
			})
		},
		watch: {
			'income.q1'(val){
				this.$store.dispatch('setUploadList', {
					id: 4,
					active: val == 1 ? true : false
				})
			},
			'income.q2'(val){
				this.$store.dispatch('setUploadList', {
					id: 5,
					active: val == 1 ? true : false
				})
			},
			'income.q3'(val){
				this.$store.dispatch('setUploadList', {
					id: 6,
					active: val == 1 ? true : false
				})
			},
			'income.q4'(val){
				this.$store.dispatch('setPayment', {
					section: 3,
					question: 4,
					active: val == 1 ? true : false
				})

				this.$store.dispatch('setUploadList', {
					id: 7,
					active: val == 1 ? true : false
				})
			},
			'income.q5'(val){
				if(this.income.hasOwnProperty('q13')){
					if(this.income.q13 == 0){
						this.$store.dispatch('setUploadList', {
							id: 8,
							active: val == 1 ? true : false
						})
					}
				}
				else{
					this.$store.dispatch('setUploadList', {
						id: 8,
						active: val == 1 ? true : false
					})
				}
			},
			'income.q6'(val){
				if(this.income.hasOwnProperty('q8')){
					if(this.income.q8 == 0){
						this.$store.dispatch('setUploadList', {
							id: 9,
							active: val == 1 ? true : false
						})
					}
				}
				else{
					this.$store.dispatch('setUploadList', {
						id: 9,
						active: val == 1 ? true : false
					})
				}
			},
			'income.q7_ex_spouses_ssn': {
				deep: true,
				handler(val){
					for(let i = 0; i < val.length; i++){
						if(val[i].ssn.length == 3 || val[i].ssn.length == 6){
							this.income.q7_ex_spouses_ssn[i].ssn = `${val[i].ssn}-`
						}
					}
				}
			},
			'income.q8'(val){
				if(this.income.hasOwnProperty('q6')){
					if(this.income.q6 == 0){
						this.$store.dispatch('setUploadList', {
							id: 9,
							active: val == 1 ? true : false
						})
					}
				}
				else{
					this.$store.dispatch('setUploadList', {
						id: 9,
						active: val == 1 ? true : false
					})
				}
			},
			'income.q9'(val){
				this.$store.dispatch('setUploadList', {
					id: 10,
					active: val == 1 ? true : false
				})
			},
			'income.q10'(val){
				this.$store.dispatch('setUploadList', {
					id: 11,
					active: val == 1 ? true : false
				})
			},
			'income.q11'(val){
				if(val == 0){
					this.income.q11_income = [{}]
					this.getSelectedIncome()
				}
			},
			'income.q13'(val){
				if(this.income.hasOwnProperty('q5')){
					if(this.income.q5 == 0){
						this.$store.dispatch('setUploadList', {
							id: 8,
							active: val == 1 ? true : false
						})
					}
				}
				else{
					this.$store.dispatch('setUploadList', {
						id: 8,
						active: val == 1 ? true : false
					})
				}
			},
		},
		methods: {
			addField(field){
				let items = {}
				if(field == 'q7_ex_spouses_ssn'){
					items = {
						ssn: ''
					}
				}
				this.income[field].push(items)
			},
			removeField(field, index){
				this.income[field].splice(index, 1)
				if(field == 'q11_income'){
					this.getSelectedIncome()
				}
			},
			getSelectedIncome(){
				let enableSection5 = false
				let enableSection7 = false

				for(let i = 0; this.incomeOptions.length > i; i++){
					if([1,2,5].includes(this.incomeOptions[i].id)){
						if(this.incomeOptions[i].id == 1 && this.section5Condition.genInfoQuestion9 == false){
							this.$store.dispatch('setPayment', {
								section: 3,
								question: '11_income',
								answer: 1,
								active: this.income.q11_income.includes(this.incomeOptions[i].id)
							})
						}
						else if(this.incomeOptions[i].id == 2 && this.section7Condition.genInfoQuestion10 == false){
							this.$store.dispatch('setPayment', {
								section: 3,
								question: '11_income',
								answer: 2,
								active: this.income.q11_income.includes(this.incomeOptions[i].id)
							})
						}
						else if(this.incomeOptions[i].id == 5){
							this.$store.dispatch('setPayment', {
								section: 3,
								question: '11_income',
								answer: 5,
								active: this.income.q11_income.includes(this.incomeOptions[i].id)
							})
						}
					}
				}

				enableSection5 = this.income.q11_income.find(x => x == 1) == 1 ? true : false
				enableSection7 = this.income.q11_income.find(x => x == 2) == 2 ? true : false

				this.$store.dispatch('toggleSection5', {
					section: 'income',
					active: enableSection5
				})

				this.$store.dispatch('toggleSection7', {
					section: 'income',
					active: enableSection7
				})
			},
			storeForm(){
				this.$store.dispatch('setIncomeForm', this.income)
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData' && this.$store.state.incomeModule.incomeForm.length != 0){
				this.income = this.$store.state.incomeModule.incomeForm
				if(this.income.q2_info == null){
					this.income.q2_info = [
						{
							bank: '',
							amount: ''
						}
					]
				}
				if(this.income.q3_info == null){
					this.income.q3_info = [
						{
							bank: '',
							amount: ''
						}
					]
				}
				if(this.income.q6_info == null){
					this.income.q6_info = [
						{
							bank: '',
							amount: ''
						}
					]
				}
				if(this.income.q7_ex_spouses_ssn == null){
					this.income.q7_ex_spouses_ssn = [{
						ssn: ''
					}]
				}
				if(this.income.q8_info == null){
					this.income.q8_info = [
						{
							bank: '',
							amount: ''
						}
					]
				}
				if(this.income.q11_income == null){
					this.income.q11_income = [{}]
				}
				else{
					this.getSelectedIncome()
				}
				if(this.income.q12_contrib == null){
					this.income.q12_contrib = [
						{
							ira: '',
							amount: ''
						}
					]
				}
				if(this.income.q13_1 == null){
					this.income.q13_1 = [
						{
							amount: ''
						}
					]
				}
			}
		},
		deactivated(){
			this.storeForm()
		}
	}
</script>