<template>
	<v-container fill-height width="200px" fluid class="pa-0">
		<v-navigation-drawer permanent style="background-image: url('../img/bg.png'); background-size: auto 100%; background-color: #F4F4F4;" class="fill-height" width="100%">
			<v-list-item>
				<v-img
			     	height="200"
			     	contain
			    	:src="'../img/cp-full-logo.png'"
			    	class="mx-auto mx-0"
			    ></v-img>
			</v-list-item>
			<!-- removed bill in request of client -->
			<!-- <v-row class="mx-auto mb-5">
				<v-col cols="12">
					<div class="text-h5">Bill: <span v-if="activeSection == 'Options'">${{ finalBill }}</span><span v-else>--</span></div>
				</v-col>
			</v-row> -->
			<v-row v-for="(step, index) in steps" :key="index" class="mx-auto">
				<v-col cols="12">
					<div class="text-subtitle-2 font-weight-bold">
						<v-avatar
							v-if="step.status == 'active' || step.status == 'done'"
							size="20"
							color="primary">
							<v-icon color="white" v-if="step.status == 'done'">mdi-check</v-icon>
							<span class="white--text" v-else>{{ step.step }}</span>
						</v-avatar>
						<v-avatar
							v-if="step.status == 'pending'"
							size="20"
							color="disabled">
							<span class="white--text">{{ step.step }}</span>
						</v-avatar>
						<span color="disabled" :class="{ 'grey--text': step.status == 'pending' }">{{ step.text }}</span>
					</div>
				</v-col>
			</v-row>
		</v-navigation-drawer>
	</v-container>
</template>
<script>
	import { mapState, mapGetters } from 'vuex'
	export default{
		computed: {
			...mapState({
				activeSection: state => state.activeSection
			}),
			...mapGetters([
				'steps',
				'finalBill'
			])
		},
	}
</script>