import Vue from 'vue'
import Vuex from 'vuex'

import demographicsModule from './demographics'
import generalInformationModule from './generalInformation'
import incomeModule from './income'
import personalDeductionsModule from './personalDeductions'
import businessDeductionsModule from './businessDeductions'
import rentalModule from './rental'
import optionsModule from './options'

Vue.use(Vuex)

const defaultState = () => {
	return {
		endpoint: '/api/v1',
		steps: [
			{
				step: 1,
				text: 'Demographics',
				component: 'Demographics',
				enabled: true,
				status: 'active'
			},
			{
				step: 2,
				text: 'General Information',
				component: 'GeneralInformation',
				enabled: true,
				status: 'pending'
			},
			{
				step: 3,
				text: 'Income',
				component: 'Income',
				enabled: true,
				status: 'pending'
			},
			{
				step: 4,
				text: 'Personal Deductions (Schedule A)',
				component: 'PersonalDeductions',
				enabled: false,
				status: 'pending'
			},
			{
				step: 5,
				text: 'Business Deductions (Schedule C/Form 8829)',
				component: 'BusinessDeductions',
				enabled: false,
				status: 'pending'
			},
			{
				step: 6,
				text: 'Rental Income and Activity (Schedule E)',
				component: 'RentalIncomeAndActivity',
				enabled: false,
				status: 'pending'
			},
			{
				step: 7,
				text: 'Options Within Your Control – Choose Your Own Price',
				component: 'Options',
				enabled: true,
				status: 'pending'
			}
		],
		section5Condition: {
			genInfoQuestion9: '',
			incomeQuestion11: ''
		},
		section7Condition: {
			genInfoQuestion10: '',
			incomeQuestion11: ''
		},
		activeSection: 'Demographics', //default
		questionWithPayments: [ //current price as of this writing
			{
				section: 2,
				question: 7,
				value: 10,
				action: 'add',
				active: false
			},
			{
				section: 2,
				question: 9,
				value: 75,
				action: 'add',
				active: false
			},
			{
				section: 2,
				question: 11,
				value: 75,
				action: 'add',
				active: false
			},
			{
				section: 2,
				question: 12,
				value: 15,
				action: 'add',
				active: false
			},
			{
				section: 2,
				question: 13,
				value: 10,
				action: 'add',
				active: false
			},
			{
				section: 2,
				question: 15,
				value: 10,
				action: 'add',
				active: false
			},
			{
				section: 2,
				question: 16,
				value: 75,
				action: 'add',
				active: false
			},
			{
				section: 3,
				question: 4,
				value: 40,
				action: 'add',
				active: false
			},
			{
				section: 3,
				question: '11_income',
				answer: 1, //Business Income
				value: 75,
				action: 'add',
				active: false,
				disabledByQ9: false //condition for section 2 question 9 checker
			},
			{
				section: 3,
				question: '11_income',
				answer: 2, //Rental Income
				value: 75,
				action: 'add',
				active: false,
				disabledByQ10: false //condition for section 2 question 10 checker
			},
			{
				section: 3,
				question: '11_income',
				answer: 5, //Foreign Earned Income
				value: 10,
				action: 'add',
				active: false,
			},
			{
				section: 4,
				question: 9,
				value: 10,
				action: 'add',
				active: false
			},
			{
				section: 4,
				question: 10,
				value: 15,
				action: 'add',
				active: false
			},
			{
				section: 5,
				question: 2,
				value: 75,
				action: 'add',
				active: false
			},
			{
				section: 7, // section7 is Rental Income and Activity since section 6 was requested to be removed
				question: 1,
				value: 75, // depends on the computation based on how many properties
				basePropertyPrice: 75, // price for the first property
				additionalPropertyPrice: 25, // price for the additional properties
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 1,
				answer: 1, //Tax Accountant
				value: 60,
				action: 'discount',
				active: false
			},
			{
				section: 8,
				question: 1,
				answer: 2, //Senior Tax Accountant
				value: 0,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 1,
				answer: 3, //CPA Enrolled Agent
				value: 150,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 2,
				answer: 1, //Senior Tax Account
				value: 0,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 2,
				answer: 2, //CPA Enrolled Agent
				value: 50,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 3,
				answer: 1, //Yes, one state return
				value: 0,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 3,
				answer: 2, //Yes, two state returns
				value: 15,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 3,
				answer: 0, //No
				value: 15,
				action: 'discount',
				active: false
			},
			{
				section: 8,
				question: 4,
				answer: 1, //3-5 business days
				value: 75,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 4,
				answer: 2, //6-10 business days
				value: 0,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 4,
				answer: 3, //11-14 business days
				value: 15,
				action: 'discount',
				active: false
			},
			{
				section: 8,
				question: 5,
				answer: 1, //Electronic Copy
				value: 10,
				action: 'discount',
				active: false
			},
			{
				section: 8,
				question: 5,
				answer: 2, //Mail me a copy
				value: 15,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 6,
				answer: 1, //Bank account
				value: 3,
				action: 'percentDiscount',
				active: false
			},
			{
				section: 8,
				question: 6,
				answer: 2, //Credit Card
				value: 0,
				action: 'add',
				active: false
			},
			{
				section: 8,
				question: 6,
				answer: 3, //Tax refund
				value: 101,
				action: 'add',
				active: false
			},
		],
		defaultBasePrice: 309, //base price if not a returning client
		basePrice: 309, // base price that may change if the user is a returning client
		uploadDocumentList: [
			{
				id: 1,
				document: 'Form 1095-A',
				active: false,
				checked: false
			},
			{
				id: 2,
				document: 'Bank Documentation (cancelled check, bank screen shot, or bank letter)',
				active: false,
				checked: false
			},
			{
				id: 3,
				document: 'State Tax Payment History',
				active: false,
				checked: false
			},
			{
				id: 4,
				document: 'W2 Forms',
				active: false,
				checked: false
			},
			{
				id: 5,
				document: '1099INT Forms',
				active: false,
				checked: false
			},
			{
				id: 6,
				document: '1099-DIV Forms',
				active: false,
				checked: false
			},
			{
				id: 7,
				document: '1099B & 1099S Forms',
				active: false,
				checked: false
			},
			{
				id: 8,
				document: '1099R Forms',
				active: false,
				checked: false
			},
			{
				id: 9,
				document: '1099G Forms',
				active: false,
				checked: false
			},
			{
				id: 10,
				document: 'SSA-1099 and/or RRB-1099-R',
				active: false,
				checked: false
			},
			{
				id: 11,
				document: '1099-C',
				active: false,
				checked: false
			},
			{
				id: 12,
				document: 'Real Estate Tax Payment History',
				active: false,
				checked: false
			},
			{
				id: 13,
				document: 'Personal Property Tax Payment History',
				active: false,
				checked: false
			},
			{
				id: 14,
				document: 'Form 1098',
				active: false,
				checked: false
			},
			{
				id: 15,
				document: 'Form 1098-E',
				active: false,
				checked: false
			},
			{
				id: 16,
				document: 'Charitable giving statements, Email verifications, or Pictures of cancelled checks',
				active: false,
				checked: false
			},
			{
				id: 17,
				document: 'Form 1098-T',
				active: false,
				checked: false
			},
			{
				id: 18,
				document: 'Spreadsheet, Bookkeeping report, or other documentation that lists those Business Expenses',
				active: false,
				checked: false
			},
			{
				id: 19,
				document: '', //depends if the taxpayer has supporting docs
				active: false,
				checked: false
			},
			{
				id: 20,
				document: '', //depends if the taxpayer has supporting docs
				active: false,
				checked: false
			},
			{
				id: 21,
				document: 'Front and back of your driver\’s license or most recent picture id issued from a government agency',
				active: true, //default value is true since it's always required
				checked: false
			}
		],
		isReturningClient: false,
		hasExistingTaxYear: false
	}
}

export default new Vuex.Store({
	modules: {
		demographicsModule,
		generalInformationModule,
		incomeModule,
		personalDeductionsModule,
		businessDeductionsModule,
		rentalModule,
		optionsModule,
	},
	state: defaultState(),
	getters: {
		steps(state){
			let enabledSteps = state.steps.filter(step => step.enabled == true)
			for(let i = 0; i < enabledSteps.length; i++){
				enabledSteps[i].step = i+1
			}
			return enabledSteps
		},
		finalBill(state){
			let activeAddPayments = state.questionWithPayments.filter(question => question.active == true && question.action == 'add')
			let additionalPaymentSum = 0
			for(let i = 0; i < activeAddPayments.length; i++){
				additionalPaymentSum += activeAddPayments[i].value
			}

			let activeDiscountPayments = state.questionWithPayments.filter(question => question.active == true && question.action == 'discount')
			let discountSum = 0
			for(let i = 0; i < activeDiscountPayments.length; i++){
				discountSum += activeDiscountPayments[i].value
			}

			let total = state.basePrice + additionalPaymentSum - discountSum
			let percentDiscount = state.questionWithPayments.filter(question => question.active == true && question.action == 'percentDiscount')

			if(percentDiscount.length != 0){
				total = total-(total*(percentDiscount[0].value/100))
			}

			return total.toFixed(2)
		},
		uploadDocumentList(state){
			return state.uploadDocumentList.filter(x => x.active == true)
		},
		level(state){
			let enabledSchedules = state.steps.filter(x => x.enabled == true && ['PersonalDeductions', 'BusinessDeductions', 'RentalIncomeAndActivity'].indexOf(x.component) != -1)
			let level = 1
			
			if(enabledSchedules.length == 1){
				level = 2
			}
			else if(enabledSchedules.length >= 2){
				level = 3
			}
			else{
				level = 1
			}

			return level
		}
	},
	mutations: {
		TOGGLE_SECTION4(state, bool){
			state.steps[3].enabled = bool 
		},
		TOGGLE_SECTION5(state, bool){
			state.steps[4].enabled = bool
		},
		TOGGLE_SECTION7(state, bool){
			state.steps[5].enabled = bool 
		},
		SET_SECTION5_CONDITION(state, payload){
			if(payload.section == 'genInfo'){
				state.section5Condition.genInfoQuestion9 = payload.active
			}
			else if(payload.section == 'income'){
				state.section5Condition.incomeQuestion11 = payload.active
			}
		},
		SET_SECTION7_CONDITION(state, payload){
			if(payload.section == 'genInfo'){
				state.section7Condition.genInfoQuestion10 = payload.active
			}
			else if(payload.section == 'income'){
				state.section7Condition.incomeQuestion11 = payload.active
			}
		},
		MOVE_STEP(state, action){
			let activeStep = state.steps.filter(step => step.status == 'active')
			let enabledSteps = state.steps.filter(step => step.enabled == true)
			for(let i = 0; i < enabledSteps.length; i++){
				enabledSteps[i].step = i+1
			}
			if(action == 'next'){
				enabledSteps[activeStep[0].step-1].status = 'done'
				enabledSteps[activeStep[0].step].status = 'active'
				state.activeSection = enabledSteps[activeStep[0].step].component
			}
			else{
				enabledSteps[activeStep[0].step-2].status = 'active'
				enabledSteps[activeStep[0].step-1].status = 'pending'
				state.activeSection = enabledSteps[activeStep[0].step-2].component
			}
		},
		SET_PAYMENT(state, payload){
			if(payload.hasOwnProperty('disabledByQ9')){
				state.questionWithPayments[payload.index].disabledByQ9 = payload.disabledByQ9
			}
			if(payload.hasOwnProperty('disabledByQ10')){
				state.questionWithPayments[payload.index].disabledByQ10 = payload.disabledByQ10
			}
			if(payload.hasOwnProperty('value')){
				state.questionWithPayments[payload.index].value = payload.value
			}
			state.questionWithPayments[payload.index].active = payload.active
		},
		SET_UPLOAD_LIST(state, payload){
			state.uploadDocumentList[payload.index].active = payload.active
			if(payload.hasOwnProperty('document')){
				state.uploadDocumentList[payload.index].document = payload.document
			}
		},
		SUBMIT_SURVEY(state){
			let lastIndex = state.steps.findIndex(x => x.status == 'active')
			state.activeSection = 'EndPage'
			state.steps[lastIndex].status = 'done'
		},
		RESET_STATE(state){
			Object.assign(state, defaultState())
			state.demographicsModule.demographicsForm = []
			state.generalInformationModule.generalInfoForm = []
			state.incomeModule.incomeForm = []
			state.personalDeductionsModule.personalDeductionsForm = []
			state.businessDeductionsModule.businessDeductionsForm = []
			state.rentalModule.rentalForm = []
			state.optionsModule.optionsForm = []
		},
		SET_RETURNING_CLIENT(state, status){
			state.isReturningClient = status
		},
		SET_PRICING(state, questionWithPayments){
			state.questionWithPayments = questionWithPayments
		},
		SET_DEFAULT_BASE_PRICE(state, basePrice){
			state.defaultBasePrice = basePrice
		},
		SET_BASE_PRICE(state, basePrice){
			state.basePrice = basePrice
		}
	},
	actions: {
		toggleSection4({commit}, bool){
			commit('TOGGLE_SECTION4', bool)
		},
		toggleSection5({commit, state}, payload){
			commit('SET_SECTION5_CONDITION', payload)
			let bool = state.section5Condition.genInfoQuestion9 || state.section5Condition.incomeQuestion11 ? true : false
			commit('TOGGLE_SECTION5', bool)
		},
		toggleSection7({commit, state}, payload){
			commit('SET_SECTION7_CONDITION', payload)
			let bool = state.section7Condition.genInfoQuestion10 || state.section7Condition.incomeQuestion11 ? true : false
			commit('TOGGLE_SECTION7', bool)
		},
		moveStep({commit}, action){
			commit('MOVE_STEP', action)
		},
		setPayment({commit, state}, payload){
			let index = 0
			if(payload.section == 8 || (payload.section == 3 && payload.question == '11_income')){
				index = state.questionWithPayments.findIndex(x => x.section == payload.section && x.question == payload.question && x.answer == payload.answer)
			}
			else{
				index = state.questionWithPayments.findIndex(x => x.section == payload.section && x.question == payload.question)
			}

			let paymentPayload = {
				index: index,
				active: payload.active
			}
			if(payload.hasOwnProperty('disabledByQ9')){
				paymentPayload['disabledByQ9'] = payload.disabledByQ9
			}
			if(payload.hasOwnProperty('disabledByQ10')){
				paymentPayload['disabledByQ10'] = payload.disabledByQ10
			}
			if(payload.section == 7 && payload.question == 1){ //rental activity section first question
				let rentalQ1Index = state.questionWithPayments.findIndex(x => x.section == 7 && x.question == 1)
				paymentPayload['value'] = payload.noOfProperties != 0 ? ((payload.noOfProperties - 1) * state.questionWithPayments[rentalQ1Index].additionalPropertyPrice) + state.questionWithPayments[rentalQ1Index].basePropertyPrice : 0
			}
			commit('SET_PAYMENT', paymentPayload)
		},
		setUploadList({commit, state}, payload){
			let index = state.uploadDocumentList.findIndex(x => x.id == payload.id)
			let payload1 = {
				index: index,
				active: payload.active
			}
			if(payload.hasOwnProperty('document')){
				payload1['document'] = payload.document
			}
			commit('SET_UPLOAD_LIST', payload1)
		},
		async submitSurvey({commit, state, getters}, isCompleted){
			let payload = new FormData()
			payload.append('demographics', JSON.stringify(state.demographicsModule.demographicsForm))
			payload.append('genInfo', JSON.stringify(state.generalInformationModule.generalInfoForm))
			payload.append('level', getters.level)
			// removed bill due to disabled pricing feature
			// payload.append('bill', getters.finalBill)
			payload.append('steps', isCompleted == false && state.activeSection == 'Options' ? JSON.stringify(state.steps) : null)
			payload.append('question_payments', isCompleted == false && state.activeSection == 'Options' ? JSON.stringify(state.questionWithPayments) : null)
			payload.append('upload_document_list', isCompleted == false && state.activeSection == 'Options' ? JSON.stringify(state.uploadDocumentList) : null)
			payload.append('is_completed', isCompleted == true ? '1' : '0')

			// if(state.generalInformationModule.generalInfoForm.hasOwnProperty('q1')){
			// 	if(state.generalInformationModule.generalInfoForm.q1.length != 0 && typeof state.generalInformationModule.generalInfoForm.q1[0].name == 'string'){
			// 		state.generalInformationModule.generalInfoForm.q1.forEach(function(img){
			// 			payload.append('gen_info_tp_attachments[]', img, img.name)
			// 		})
			// 	}
			// }

			// if(state.demographicsModule.demographicsForm.filing_status == 2){
			// 	if(state.generalInformationModule.generalInfoForm.hasOwnProperty('q1_1') && state.generalInformationModule.generalInfoForm.q1_1.length != 0 && typeof state.generalInformationModule.generalInfoForm.q1_1[0].name == 'string'){
			// 		state.generalInformationModule.generalInfoForm.q1_1.forEach(function(img){
			// 			payload.append('gen_info_spouse_attachments[]', img, img.name)
			// 		})
			// 	}
			// }

			if(state.incomeModule.incomeForm.length != 0){
				payload.append('income', JSON.stringify(state.incomeModule.incomeForm))
			}

			if(state.optionsModule.optionsForm.length != 0){
				payload.append('options', JSON.stringify(state.optionsModule.optionsForm))
			}

			if(state.steps.findIndex(x => x.component == 'PersonalDeductions' && x.enabled == true) != -1){
				if(state.personalDeductionsModule.personalDeductionsForm.length != 0){
					payload.append('personalDeductions', JSON.stringify(state.personalDeductionsModule.personalDeductionsForm))
				}
			}

			if(state.steps.findIndex(x => x.component == 'BusinessDeductions' && x.enabled == true) != -1){
				if(state.businessDeductionsModule.businessDeductionsForm.length != 0){
					payload.append('businessDeductions', JSON.stringify(state.businessDeductionsModule.businessDeductionsForm))
				}
			}

			if(state.steps.findIndex(x => x.component == 'RentalIncomeAndActivity' && x.enabled == true) != -1){
				if(state.rentalModule.rentalForm.length != 0){
					payload.append('rental', JSON.stringify(state.rentalModule.rentalForm))
				}
			}

			// if(state.optionsModule.optionsForm.hasOwnProperty('uploads') && state.optionsModule.optionsForm.uploads.length != 0 && typeof state.optionsModule.optionsForm.uploads[0].name == 'string'){
			// 	if(state.optionsModule.optionsForm.uploads.length > 0){
			// 		state.optionsModule.optionsForm.uploads.forEach(function(img){
			// 			payload.append('documents[]', img, img.name)
			// 		})
			// 	}
			// }

			await axios.post(`${state.endpoint}/tax-organizer/save`, payload, {
				headers: {
					'Authorization': 'Bearer '+process.env.MIX_API_TOKEN,
					'Accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8',
					'Content-Type': `multipart/form-data; boundary=${payload._boundary}`,
				}
			})
			.then(response => {
				
			})

			commit('SUBMIT_SURVEY')
		},
		resetState({commit, dispatch}){
			commit('RESET_STATE')
			dispatch('getPricing', true)

		},
		async getTaxPayerInfo({commit, state}, payload){
			await axios.get(`${state.endpoint}/tax-organizer/${payload.ssn}/${payload.dob}/null?returningClient=true`,{
				headers: {
					'Authorization': 'Bearer '+process.env.MIX_API_TOKEN,
					'Accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8'
				}
			})
			.then(response => {
				let status = ''
				if(response.data.hasOwnProperty('data')){
					status = 'hasExistingData'
					state.demographicsModule.demographicsForm = {...response.data.data.demographics[0], ...response.data.data.tax_payer}
					if(response.data.data.is_completed == '0'){
						if(response.data.data.tax_payer.previous_bill == null){
							state.basePrice = state.defaultBasePrice
						}
						else{
							state.basePrice = response.data.data.tax_payer.previous_bill
						}

						if(response.data.data.general_information != 0){
							state.generalInformationModule.generalInfoForm = response.data.data.general_information[0]
						}
						if(response.data.data.income.length != 0){
							state.incomeModule.incomeForm = response.data.data.income[0]
						}
						if(response.data.data.personal_deduction.length != 0){
							state.personalDeductionsModule.personalDeductionsForm = response.data.data.personal_deduction[0]
						}
						if(response.data.data.business_deduction.length != 0){
							state.businessDeductionsModule.businessDeductionsForm = response.data.data.business_deduction[0]
						}
						if(response.data.data.rental_income_and_activities.length != 0){
							state.rentalModule.rentalForm = response.data.data.rental_income_and_activities[0]
						}
						if(response.data.data.options.length != 0){
							state.activeSection = 'Options'
							state.optionsModule.optionsForm = response.data.data.options[0]
							if(response.data.data.documents.length != 0){
								state.optionsModule.optionsForm.list = response.data.data.documents[0].list
							}
							state.steps = response.data.data.steps
							state.questionWithPayments = response.data.data.question_payments
							state.uploadDocumentList = response.data.data.upload_document_list
						}
					}
					else{
						state.basePrice = response.data.data.tax_payer.bill
					}
				}
				else{
					status = 'noExistingData'
				}
				commit('SET_RETURNING_CLIENT', status)
			})
		},
		async checkExistingTaxYear({commit, state}, payload){
			await axios.get(`${state.endpoint}/tax-organizer/check-existing-data/${payload.ssn}/${payload.dob}/${payload.year}`, {
				headers: {
					'Authorization': 'Bearer '+process.env.MIX_API_TOKEN,
					'Accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8'
				}
			})
			.then(response => {
				state.hasExistingTaxYear = response.data.hasExistingTaxYear
				if(response.data.previous_bill != null && response.data.hasExistingTaxYear == false){
					state.basePrice = response.data.previous_bill
				}
			})
		},
		async getPricing({commit, state}, setDefaultBasePrice){ //set setDefaultBasePrice to true if also update the default base price
			await axios.get(`${state.endpoint}/question-price/get-pricing`, {
				headers: {
					'Authorization': 'Bearer '+process.env.MIX_API_TOKEN,
					'Accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8'
				}
			})
			.then(response => {
				commit('SET_PRICING', response.data.data.pricing)
				commit('SET_BASE_PRICE', response.data.data.base_price)
				if(setDefaultBasePrice){
					commit('SET_DEFAULT_BASE_PRICE', response.data.data.base_price)
				}
			})
		},
		async setPricing({state}, payload){
			await axios.post(`${state.endpoint}/question-price/set-pricing`, payload, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`, //different token as it is given to admin account
					'Accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8'
				}
			})
		}
	}
})