import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '../components/views/Home/Index'
import TaxOrganizer from '../components/views/TaxOrganizer/Index'
// import AdminLogin from '../components/views/Admin/Index'
// import Pricing from '../components/views/Admin/Pricing'

const routes = [
	{
		component: Home,
		name: 'home',
		path: '/'
	},
	{
		path: '/tax-organizer',
		component: TaxOrganizer,
		name: 'taxorganizer'
	},
	// removed edit price page due to pricing feature removal
	// {
	// 	path: '/admin',
	// 	component: AdminLogin,
	// 	name: 'adminlogin'
	// },
	// {
	// 	path: '/pricing',
	// 	component: Pricing,
	// 	name: 'pricing',
	// 	meta: {
	// 		requiresAuth: true
	// 	}
	// }
]

let router = new VueRouter({
	mode: 'history',
	routes
})

//check if route requires authentication
router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAuth)){
		if(localStorage.getItem('bearerToken') == null){
			next({
				path: '/admin'
			})
		}
		else {
			next()
		}
	}
	else{
		next()
	}
})

export default router