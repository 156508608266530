<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" align="center">
				<div class="text-h3">Thank you</div>
			</v-col>
			<v-col cols="12" align="center">
				<v-btn color="primary" @click="goToHomePage">Return To Home Page</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	export default{
		methods: {
			goToHomePage(){
				this.$store.dispatch('resetState')
				this.$router.push('/')
			}
		}
	}
</script>