var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-card',{attrs:{"flat":"","color":"backgroundColor","width":"800px"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"height":"200","contain":"","src":'../img/cp-full-logo.png'}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-container',{attrs:{"fluid":""}},[_c('video',{staticClass:"mx-auto",attrs:{"width":_vm.videoWidth,"controls":""}},[_c('source',{attrs:{"src":'../video/welcome.mp4',"type":"video/mp4"}})])])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","align":"center"}},[_c('v-dialog',{attrs:{"width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"250","block":_vm.$vuetify.breakpoint.smAndDown,"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Returning Client")])]}}]),model:{value:(_vm.returningClientDialog),callback:function ($$v) {_vm.returningClientDialog=$$v},expression:"returningClientDialog"}},[_c('v-card',{staticClass:"backgroundColor pt-3",attrs:{"width":"600px"}},[_c('v-container',{staticClass:"px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"SSN","outlined":"","background-color":"white","maxlength":"11"},on:{"keypress":_vm.numberOnly},model:{value:(_vm.ssn),callback:function ($$v) {_vm.ssn=$$v},expression:"ssn"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.birthdate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.birthdate=$event},"update:return-value":function($event){_vm.birthdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DOB","outlined":"","background-color":"white","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.birthdate),callback:function ($$v) {_vm.birthdate=$$v},expression:"birthdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepickerDialog),callback:function ($$v) {_vm.datepickerDialog=$$v},expression:"datepickerDialog"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.birthdatePicker),callback:function ($$v) {_vm.birthdatePicker=$$v},expression:"birthdatePicker"}},[_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"blue--text"},[_vm._v("mdi-information-outline")]),_c('span',{staticClass:"text-caption blue--text"},[_vm._v("NOTE")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("You may click on the month and year between the left and right arrows to easily navigate with the date picker\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datepickerDialog = false}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.birthdate)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tOK\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","align":"center"}},[_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary"},on:{"click":function($event){_vm.returningClientDialog = false}}},[_vm._v("Cancel")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","align":"center"}},[_c('v-btn',{staticClass:"primary",attrs:{"block":""},on:{"click":_vm.fetchTaxPayerInfo}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","align":"center"}},[_c('v-btn',{attrs:{"width":"250","block":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","to":'/tax-organizer'}},[_vm._v("New Client")])],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-caption grey--text"},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-information-outline")]),_vm._v(" For previous clients or continue answering survey")],1)])],1)],1)],1)],1)],1),_vm._v(" "),(_vm.isLoading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }