export default {
	state: {
		incomeForm: []
	},
	mutations: {
		SET_INCOME_FORM(state, income){
			state.incomeForm = income
		}
	},
	getters: {

	},
	actions: {
		setIncomeForm({commit}, income){
			commit('SET_INCOME_FORM', income)
		}
	}
}