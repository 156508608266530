<template>
	<v-container fluid>
		<v-form ref="GeneralInformation">
			<v-row class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">1. Please upload a copy (picture from phone or scanned copy) of the front and back of your driver’s license or most recent picture id issued from a government agency. NOTE: this is a required field and should be done each year.</div>
				</v-col>
				<!-- <v-col cols="12" sm="12" md="6">
					<v-file-input
						v-model="generalInformation.q1"
						label="Recent picture ID issued by government agency"
						accept="image/*,application/pdf"
						hint="Allowed file types: images, pdf"
						multiple
						type="file"
						outlined background-color="white"
						:show-size="true"
						:rules="fileSizeRule"
						></v-file-input>
				</v-col>
				<v-col cols="12" sm="12" md="6" v-if="filingStatus == 2">
					<v-file-input
						v-model="generalInformation.q1_1"
						label="Spouse recent picture ID issued by government agency"
						accept="image/*,application/pdf"
						hint="Allowed file types: images, pdf"
						multiple
						type="file"
						outlined background-color="white"
						:rules="fileSizeRule"
						></v-file-input>
				</v-col> -->
				<v-col cols="12" sm="12" md="12">
					<v-alert color="primary lighten-2" class="white--text" dense><v-icon color="white">mdi-information-outline</v-icon> This is a required document for upload. Prior to completing the tax organizer you will be prompted to upload all required documents using the link provided.</v-alert>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">2. Has the IRS assigned you and/or your spouse an Identity Protection PIN that is required to be filed along with your return because of problems with identify theft in the past?</div>
				</v-col>
				<v-col cols="12" sm="12" md="6">
					<v-radio-group row v-model="generalInformation.q2" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
				<v-col cols="12" sm="12" md="6" v-if="generalInformation.q2 == 1">
					<v-text-field label="Identity Protection PIN" outlined background-color="white" v-model="generalInformation.q2_pin" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">3. Did either you, your spouse, or a dependent buy or sell electronic currency during the year (such as bitcoin)?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q3" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">4. Did you have any monetary or financial accounts located outside of the United States at any time during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q4" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters v-if="generalInformation.q4 == 1" class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">Did the combined maximum balance of all non-U.S. accounts (personal & signature or electronic authority) ever exceed USD $10,000 at any point in the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q4_1" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">5. Did you move or have a change of address during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q5" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="generalInformation.q5 == 1" class="mb-8">
				<v-row no-gutters class="mb-8">
					<v-col cols="12">
						<div class="text-subtitle-1">What was the previous address?</div>
					</v-col>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Street Address" outlined background-color="white" v-model="generalInformation.q5_1_street_address" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="City" outlined background-color="white" v-model="generalInformation.q5_1_city" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="State" outlined background-color="white" v-model="generalInformation.q5_1_state" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Zip Code" outlined background-color="white" v-model="generalInformation.q5_1_zip_code" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-row>
				<v-row no-gutters class="mb-8">
					<v-col cols="12">
						<div class="text-subtitle-1">What is the current address?</div>
					</v-col>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Street Address" outlined background-color="white" v-model="generalInformation.q5_2_street_address" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="City" outlined background-color="white" v-model="generalInformation.q5_2_city" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="State" outlined background-color="white" v-model="generalInformation.q5_2_state" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Zip Code" outlined background-color="white" v-model="generalInformation.q5_2_zip_code" :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</v-row>
				<v-row class="mb-8">
					<v-col cols="12">
						<v-dialog
							ref="dialog"
							v-model="dateMoveDialog"
							:return-value.sync="generalInformation.q5_move_date"
							persistent
							width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field 
									label="Date of Move" 
									outlined
									background-color="white"
									v-bind="attrs"
									v-on="on"
									v-model="generalInformation.q5_move_date"
									append-icon="mdi-calendar"
									:rules="requiredRule"
									readonly></v-text-field>
							</template>
							<v-date-picker
								v-model="generalInformation.q5_move_date"
								scrollable>
								<v-spacer></v-spacer>
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
										</div>
									</template>
									<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
									</span>
								</v-tooltip>
								<v-spacer></v-spacer>
								<v-btn 
									text	
									color="primary"
									@click="datepickerDialog = false">
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.dialog.save(generalInformation.q5_move_date)">
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">6. Did you own the home you lived in?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q6" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">7. Did you have health insurance during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q7" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="generalInformation.q7 == 1">
				<v-row no-gutters class="mb-8">
					<v-col cols="12">
						<div class="text-subtitle-1">
							<v-tooltip top>
								<template v-slot:activator="{on, attrs}">
									<div v-bind="attrs" v-on="on">
										Was it through the Government Marketplace? <v-icon>mdi-information-outline</v-icon>
									</div>
								</template>
								<span>If you received a tax credit to help pay for your insurance premiums check yes.   Sometimes the Government Marketplace is referred to as the “Health Insurance Marketplace” or “ObamaCare” it can be a state or federally ran website</span>
							</v-tooltip>
						</div>
					</v-col>
					<v-col cols="12">
						<v-radio-group row v-model="generalInformation.q7_1" :rules="requiredRule">
							<v-radio
								label="Yes"
								value="1"
							></v-radio>
							<v-radio
								label="No"
								value="0"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">8. Did you make contributions to charity or donations to a religious organization?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q8" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">
						9. Did you own, operate, or receive income during the year for a business that you own or for side work that you did? Did you sell products for which you were paid? Please answer “Yes” if either statement is true
					</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q9" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
					<v-alert v-if="generalInformation.q9 == 1" color="primary lighten-2" class="white--text" dense><v-icon color="white">mdi-information-outline</v-icon> You may enter your expenses later</v-alert>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">10. Did you own a rental property at any time during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q10" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">11. Did you receive a Schedule K-1 from an S-corporation because you are a shareholder in that corporation?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q11" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I don't know"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">
						<v-tooltip top>
							<template v-slot:activator="{on, attrs}">
								<div v-bind="attrs" v-on="on">
									12. Did you sell business property during the tax year or participate in an Installment Sale? <v-icon>mdi-information-outline</v-icon>
								</div>
							</template>
							<span>Business property- Would be any property used to make income. For example: Sale of a rental property 
								<br/>
								Installment Sale- Such as an owner financed mortgage
							</span>
						</v-tooltip>
					</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q12" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">13. Did you purchase any of the following solar, wind, or otherwise energy efficient equipment or appliances that may qualify for an energy credit?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q13" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
				<v-row v-if="generalInformation.q13 == 1" class="mb-8">
					<v-col cols="6">
						<v-select
							:items="appliances"
							v-model="generalInformation.q13_1"
							label="Appliances"
							outlined background-color="white"
							:rules="requiredRule"
						></v-select>
					</v-col>
					<v-col cols="6">
						<v-text-field
							v-model="generalInformation.q13_other"
							v-if="generalInformation.q13_1 == 'Other'"
							placeholder="Please specify"
							outlined background-color="white"
							:rules="requiredRule"
						></v-text-field>
					</v-col>
				</v-row>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">14. If you receive a refund on your federal or state tax return, would you like the refund direct deposited into a US-based bank account?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q14" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="generalInformation.q14 == 1">
				<v-row no-gutters class="mb-8">
					<v-col cols="12">
						<v-select
							:items="accountType"
							item-text="text"
							item-value="id"
							v-model="generalInformation.q14_1"
							label="Type"
							outlined background-color="white"
							:rules="requiredRule"
						></v-select>
					</v-col>
				</v-row>
				<template v-if="generalInformation.q14_1">
					<v-row class="mb-8">
						<v-col cols="6">
							<v-text-field v-model="generalInformation.q14_routing_number" label="Routing Number" outlined background-color="white" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="confirmRoutingNumber" label="Confirm Routing Number" outlined background-color="white" :rules="[(confirmRoutingNumber == generalInformation.q14_routing_number) || 'Routing numbers do not match']"></v-text-field>
						</v-col>
					</v-row>
					<v-row class="mb-8">
						<v-col cols="6">
							<v-text-field v-model="generalInformation.q14_account_number" label="Account Number" outlined background-color="white" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="confirmAccountNumber" label="Confirm Account Number" outlined background-color="white" :rules="[(confirmAccountNumber == generalInformation.q14_account_number) || 'Account numbers do not match']"></v-text-field>
						</v-col>
					</v-row>
				</template>
			</template>
			<v-row class="mb-8">
				<v-col cols="12">
					<v-row no-gutters>
						<v-col cols="12">
							<div class="text-subtitle-1">
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											15. Injured or Innocent Spouse: If you file married jointly and you want your refund or tax liability calculated separately from your spouse so as to collect your portion of the refund or avoid excess tax liability? <v-icon>mdi-information-outline</v-icon>
										</div>
									</template>
									<span>Injured Spouse- If one spouse owes back child support, student loans, or another debt that your tax refund will be applied to. You can file injured spouse to keep part of your refund instead of the total amount being applied to a past due debt that only one spouse is responsible for. 
									<br/>
									Innocent Spouse- Innocent Spouse Relief provides you relief from additional tax you owe if your spouse or former spouse failed to report income, reported income improperly or claimed improper deductions or credits. This is extremely rare.
									</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-radio-group row v-model="generalInformation.q15" :rules="requiredRule">
								<v-radio
									label="Yes"
									value="1"
								></v-radio>
								<v-radio
									label="No"
									value="0"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">16. Do you have stocks, bonds, or other investments that you own, purchased during the year, or sold?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q16" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
					<v-alert v-if="generalInformation.q16 == 1" color="primary lighten-2" class="white--text" dense><v-icon color="white">mdi-information-outline</v-icon> Please upload your broker financial statement showing these transactions</v-alert>
				</v-col>
			</v-row>
			<v-row no-gutters v-if="generalInformation.q16 == 1" class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">Do you know the cost basis (original cost plus adjustments) of the investment?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q16_1" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
				<v-col cols="12" v-if="generalInformation.q16_1 == 1">
					<v-text-field v-model="generalInformation.q16_cost_basis" label="Costs basis" prefix="$" outlined background-color="white" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">17. Did you receive your First Stimulus Economic Impact Payment?</div>
				</v-col>
				<v-col cols="12" sm="12" md="6">
					<v-radio-group row v-model="generalInformation.q17" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-if="generalInformation.q17 == 1">
					<v-text-field label="Please provide the total" prefix="$" outlined background-color="white" v-model="generalInformation.q17_total" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">18. Did you receive your Second Stimulus Economic Impact Payment?</div>
				</v-col>
				<v-col cols="12" sm="12" md="6">
					<v-radio-group row v-model="generalInformation.q18" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-if="generalInformation.q18 == 1">
					<v-text-field label="Please provide the total" prefix="$" outlined background-color="white" v-model="generalInformation.q18_total" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text-h6">Estimated Tax Payments Made:</div>
				</v-col>
			</v-row>
			<v-row class="mb-8">
				<v-col cols="12">
					<v-row no-gutters>
						<v-col cols="12">
							<div class="text-subtitle-1">
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											19. Did you make any estimated federal tax payments during the year? <v-icon>mdi-information-outline</v-icon>
										</div>
									</template>
									<span>These are payments you send in to cover your tax liability for the upcoming tax season. These are common for Self Employed persons or if you have investments.
									</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-radio-group row v-model="generalInformation.q19" :rules="requiredRule">
								<v-radio
									label="Yes"
									value="1"
								></v-radio>
								<v-radio
									label="No"
									value="0"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<template v-if="generalInformation.q19 == 1">
				<v-row no-gutters>
					<v-col cols="12">
						<div class="text-subtitle-1">Upload documentation showing the payments made and enter the requested info below</div>
					</v-col>
				</v-row>
				<v-row v-for="(val, index) in generalInformation.q19_qtr" :key="`${index}-q19`">
					<v-col cols="12" sm="12" md="6">
						<v-dialog
							ref="q19Dialog"
							v-model="generalInformation.q19_qtr[index].dialog"
							:return-value.sync="q19Date[index]"
							persistent
							width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field 
									label="Date" 
									outlined background-color="white" 
									v-model="q19Date[index]"
									v-bind="attrs"
									v-on="on"
									append-icon="mdi-calendar"
									:rules="requiredRule"
									readonly></v-text-field>
							</template>
							<v-date-picker
								v-model="generalInformation.q19_qtr[index].date"
								scrollable>
								<v-spacer></v-spacer>
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
										</div>
									</template>
									<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
									</span>
								</v-tooltip>
								<v-spacer></v-spacer>
								<v-btn 
									text	
									color="primary"
									@click="generalInformation.q19_qtr[index].dialog = false">
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.q19Dialog[index].save(q19Date[index])">
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="generalInformation.q19_qtr[index].amount" append-outer-icon="mdi-plus" v-if="generalInformation.q19_qtr.length == index+1" @click:append-outer="addField('q19_qtr')" :rules="requiredRule"></v-text-field>
						<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="generalInformation.q19_qtr[index].amount" append-outer-icon="mdi-minus" v-else @click:append-outer="removeField('q19_qtr', index)" :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">20. Did you make estimated state tax payments to your home state or any other state during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q20" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="generalInformation.q20 == 1">
				<v-row class="mb-8" v-for="(val, index) in generalInformation.q20_payments" :key="`${index}-q20`">
					<v-col cols="12" sm="12" md="4">
						<v-text-field label="State" outlined background-color="white" v-model="generalInformation.q20_payments[index].state" :rules="requiredRule"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="4">
						<v-dialog
							ref="q20Dialog"
							v-model="generalInformation.q20_payments[index].dialog"
							:return-value.sync="q20Date[index]"
							persistent
							width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field 
									label="Date" 
									outlined background-color="white" 
									v-model="q20Date[index]"
									v-bind="attrs"
									v-on="on"
									append-icon="mdi-calendar"
									:rules="requiredRule"
									readonly></v-text-field>
							</template>
							<v-date-picker
								v-model="generalInformation.q20_payments[index].date"
								scrollable>
								<v-spacer></v-spacer>
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
										</div>
									</template>
									<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
									</span>
								</v-tooltip>
								<v-spacer></v-spacer>
								<v-btn 
									text	
									color="primary"
									@click="generalInformation.q20_payments[index].dialog = false">
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.q20Dialog[index].save(q20Date[index])">
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
					<v-col cols="12" sm="12" md="4">
						<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="generalInformation.q20_payments[index].amount" append-outer-icon="mdi-plus" @click:append-outer="addField('q20_payments')" v-if="generalInformation.q20_payments.length == index+1" :rules="requiredRule"></v-text-field>
						<v-text-field label="Amount" prefix="$" outlined background-color="white" v-model="generalInformation.q20_payments[index].amount" append-outer-icon="mdi-minus" @click:append-outer="removeField('q20_payments', index)" v-else :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
			</template>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">21. If you were due a federal refund in the previous tax year, did you request to have that overpayment applied to this current tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q21" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I don't know, please check for me"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">22. If you were due a state refund in the previous tax year, did you request to have that overpayment applied to this current tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="generalInformation.q22" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
						<v-radio
							label="I don't know, please check for me"
							value="2"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<script>
	import dayjs from 'dayjs'
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			generalInformation: {
				q2: '',
				q2_pin: '',
				q3: '',
				q4: '',
				q5: '',
				q5_1_street_address: '',
				q5_1_city: '',
				q5_1_state: '',
				q5_1_zip_code: '',
				q5_2_street_address: '',
				q5_2_city: '',
				q5_2_state: '',
				q5_2_zip_code: '',
				q5_move_date: '',
				q6: '',
				q7: '',
				q7_1: '',
				q8: '',
				q9: '',
				q10: '',
				q11: '',
				q12: '',
				q13: '',
				q13_1: '',
				q13_other: '',
				q14: '',
				q14_1: '',
				q14_routing_number: '',
				q14_account_number: '',
				q15: '',
				q16: '',
				q16_1: '',
				q16_cost_basis: '',
				q17: '',
				q17_total: '',
				q18: '',
				q18_total: '',
				q19: '',
				q19_qtr: [
					{
						date: '',
						amount: ''
					}
				],
				q20: '',
				q20_payments: [
					{
						state: '',
						date: '',
						amount: ''
					}
				],
				q21: '',
				q22: '',
			},
			q19Date: [],
			q20Date: [],
			dateMoveDialog: false,
			appliances: [
				'Solar panels',
				'Wind mills',
				'Refrigerator',
				'Washer machine',
				'Other'
			],
			accountType: [
				{
					id: 1,
					text: 'Checking'
				},
				{
					id: 2,
					text: 'Savings'
				}
			],
			requiredRule: [v => !!v || 'Required'],
			fileSizeRule: [v => {
				if(v){
					return !v.some(file => file.size > 2e6) || 'Should be less than 2 MB per file'
				}
				else{
					return true
				}
			}],
			confirmRoutingNumber: '',
			confirmAccountNumber: ''
		}),
		computed: {
			...mapState({
				questionWithPayments: state => state.questionWithPayments,
				filingStatus: state => state.demographicsModule.demographicsForm.filing_status,
				isReturningClient: state => state.isReturningClient
			})
		},
		watch: {
			'generalInformation.q6'(){
				this.isSection4Enabled()
			},
			'generalInformation.q7'(val){
				this.$store.dispatch('setUploadList', {
					id: 1,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q7_1'(val){
				this.$store.dispatch('setPayment', {
					section: 2,
					question: 7,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q8'(){
				this.isSection4Enabled()
			},
			'generalInformation.q9'(val){
				this.isSection5Enabled()
				let q11_income = this.questionWithPayments.findIndex(x => x.section == 3 && x.question == '11_income' && x.active == true && x.answer == 1)
				if(q11_income != -1){
					this.$store.dispatch('setPayment', {
						section: 3,
						question: '11_income',
						answer: 1,
						active: false,
						disabledByQ9: true
					})
				}
				else{
					q11_income = this.questionWithPayments.findIndex(x => x.disabledByQ9 == true)
					if(q11_income != -1){
						this.$store.dispatch('setPayment', {
							section: 3,
							question: '11_income',
							answer: 1,
							active: true,
							disabledByQ9: false
						})
					}
				}
				this.$store.dispatch('setPayment', {
					section: 2,
					question: 9,
					active: val == 1 ? true : false
				})
				
			},
			'generalInformation.q10'(val){
				// requested to remove cost in this question
				// the following code is for q11 that bases its payment with q10's answer
				if(val == 0){
					if(this.generalInformation.hasOwnProperty('q11')){
						if(this.generalInformation.q11 == 1){
							this.$store.dispatch('setPayment', {
								section: 2,
								question: 11,
								active: true
							})
						}
					}
				}
				else if(val == 1){
					if(this.generalInformation.hasOwnProperty('q11')){
						if(this.generalInformation.q11 == 1){
							this.$store.dispatch('setPayment', {
								section: 2,
								question: 11,
								active: false
							})
						}
					}
				}

				// for income q#11
				let q11_income = this.questionWithPayments.findIndex(x => x.section == 3 && x.question == '11_income' && x.active == true && x.answer == 2)
				if(q11_income != -1){
					this.$store.dispatch('setPayment', {
						section: 3,
						question: '11_income',
						answer: 2,
						active: false,
						disabledByQ10: true
					})
				}
				else{
					q11_income = this.questionWithPayments.findIndex(x => x.disabledByQ10 == true)
					if(q11_income != -1){
						this.$store.dispatch('setPayment', {
							section: 3,
							question: '11_income',
							answer: 2,
							active: true,
							disabledByQ10: false
						})
					}
				}
			
				this.isSection7Enabled()
			},
			'generalInformation.q11'(val){
				let payload = {
					section: 2,
					question: 11,
					active: ''
				}
				if(val == 1){
					if(this.generalInformation.hasOwnProperty('q11')){
						if(this.generalInformation.q10 == 0){
							payload.active = true
							this.$store.dispatch('setPayment', payload)
						}
					}
				}
				else{
					if(this.generalInformation.hasOwnProperty('q11')){
						if(this.generalInformation.q10 == 0){
							payload.active = false
							this.$store.dispatch('setPayment', payload)
						}
					}
				}
			},
			'generalInformation.q12'(val){
				this.$store.dispatch('setPayment', {
					section: 2,
					question: 12,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q13'(val){
				this.$store.dispatch('setPayment', {
					section: 2,
					question: 13,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q14'(val){
				this.$store.dispatch('setUploadList', {
					id: 2,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q15'(val){
				this.$store.dispatch('setPayment', {
					section: 2,
					question: 15,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q16'(val){
				this.$store.dispatch('setPayment', {
					section: 2,
					question: 16,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q19_qtr': {
				deep: true,
				handler(val){
					for(let i = 0; i < val.length; i++){
						if(val[i].date != ''){
							this.q19Date[i] = dayjs(val[i].date).format('MM/DD/YYYY')
						}
					}
				}
			},
			'generalInformation.q20'(val){
				this.$store.dispatch('setUploadList', {
					id: 3,
					active: val == 1 ? true : false
				})
			},
			'generalInformation.q20_payments': {
				deep: true,
				handler(val){
					for(let i = 0; i < val.length; i++){
						if(val[i].date != ''){
							this.q20Date[i] = dayjs(val[i].date).format('MM/DD/YYYY')
						}
					}
				}
			}
		},
		methods: {
			isSection4Enabled(){
				let bool = (this.generalInformation.q6 == 1 || this.generalInformation.q8 == 1)
				this.$store.dispatch('toggleSection4', bool)
			},
			isSection5Enabled(){
				let bool = (this.generalInformation.q9 == 1)
				this.$store.dispatch('toggleSection5', {
					section: 'genInfo',
					active: bool
				})
			},
			isSection7Enabled(){
				let bool = (this.generalInformation.q10 == 1)
				this.$store.dispatch('toggleSection7', {
					section: 'genInfo',
					active: bool
				})
			},
			addField(field){
				this.generalInformation[field].push({
					date: ''
				})
			},
			removeField(field, index){
				this.generalInformation[field].splice(index, 1)
			},
			storeForm(){
				this.generalInformation.q19_qtr.map(x => {
					delete x.dialog
				})
				this.generalInformation.q20_payments.map(x => {
					delete x.dialog
				})
				this.$store.dispatch('setGeneralInfoForm', this.generalInformation)
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData' && this.$store.state.generalInformationModule.generalInfoForm.length != 0){
				this.generalInformation = this.$store.state.generalInformationModule.generalInfoForm
				if(this.generalInformation.q14 == '1'){
					this.confirmRoutingNumber = this.generalInformation.q14_routing_number
					this.confirmAccountNumber = this.generalInformation.q14_account_number
				}
				if(this.generalInformation.q19_qtr == null){
					this.generalInformation.q19_qtr = [{
						date: '',
						amount: ''
					}]
				}
				if(this.generalInformation.q20_payments == null){
					this.generalInformation.q20_payments = [{
						state: '',
						date: '',
						amount: ''
					}]
				}
				// if(this.$store.state.generalInformationModule.generalInfoForm.q1.length != 0){
				// 	let files = this.generalInformation.q1
				// 	this.generalInformation.q1 = files.filter(x => x.owner == 'TAX_PAYER')
				// 	this.generalInformation.q1_1 = files.filter(x => x.owner == 'SPOUSE')
				// }
			}
		},
		deactivated(){
			this.storeForm()
		}
	}
</script>