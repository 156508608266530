<template>
	<v-container fill-height width="100%" class="mx-0 py-0" fluid>
		<v-snackbar
			v-model="snackbar"
			:timeout="5000"
			top>
			{{ snackbarMessage }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="primary"
					text
					v-bind="attrs"
					@click="snackbar = false">
	          		Close
	        	</v-btn>
	    	</template>
    	</v-snackbar>
		<v-row class="fill-height">
			<v-col cols="3" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
				<Section />
			</v-col>
			<v-col cols="12" md="9" sm="12" class="pa-0">
				<v-container fill-height :class="{'pr-0 py-0 pl-12': $vuetify.breakpoint.mdAndUp}">
					<v-card
						class="overflow-y-auto fill-height"
						:class="{'pr-12' : $vuetify.breakpoint.mdAndUp}"
						width="100%"
						color="backgroundColor"
						:height="cardHeight"
						flat>
						<v-card-text :style="[$vuetify.breakpoint.mdAndUp ? {width: '850px'} : '']">
							<keep-alive>
								<component v-bind:is="activeSection" ref="section"></component>
							</keep-alive>
						</v-card-text>
					</v-card>
					<v-row v-if="activeSection != 'EndPage'">
						<v-col cols="6">
							<v-btn v-if="activeSection != 'Demographics' && activeSection != 'Options'" color="primary" outlined @click="confirmAction('back')" class="ml-5">Back</v-btn>
						</v-col>
						<v-col cols="6" align="start">
							<v-btn color="primary" @click="tempSaveConfirmation = true" v-if="activeSection != 'Demographics'" class="mr-12" text>Continue Later</v-btn>
							<v-btn color="primary" @click="confirmAction('next')" v-if="activeSection != 'Options'" class="mr-12">Next</v-btn>
							<v-btn color="primary" v-else class="mr-12" @click="confirmAction('submit')">Submit</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
		<Confirmation v-if="showConfirmation">
			<template v-slot:message>
				<h4>You will not be able to go back and change your answers once you proceed to the next section. Please take an opportunity to review your answers now. Would you like to proceed?</h4>
			</template>
			<template v-slot:action>
				<v-btn text @click="moveStep('next')">Proceed</v-btn>
				<v-spacer></v-spacer>
				<v-btn text @click="showConfirmation = false">Close and Review</v-btn>
			</template>
		</Confirmation>
		<Confirmation v-if="submitConfirmation">
			<template v-slot:message>
				<h4>Would you like to submit?</h4>
			</template>
			<template v-slot:action>
				<v-btn text @click="submitSurvey(true)">Submit</v-btn>
				<v-spacer></v-spacer>
				<v-btn text @click="submitConfirmation = false">Cancel</v-btn>
			</template>
		</Confirmation>
		<Confirmation v-if="tempSaveConfirmation">
			<template v-slot:message>
				<h4>Would you like to save and continue answering later?</h4>
			</template>
			<template v-slot:action>
				<v-btn text @click="temporarySave()">Save</v-btn>
				<v-spacer></v-spacer>
				<v-btn text @click="tempSaveConfirmation = false">Cancel</v-btn>
			</template>
		</Confirmation>
		<Loading v-if="isLoading"></Loading>
	</v-container>
</template>
<script>
	import { mapState, mapGetters } from 'vuex'
	import Section from '../../reusables/Section'
	import Demographics from './Demographics'
	import GeneralInformation from './GeneralInformation'
	import Income from './Income'
	import PersonalDeductions from './PersonalDeductions'
	import BusinessDeductions from './BusinessDeductions'
	import RentalIncomeAndActivity from './RentalIncomeAndActivity'
	import Options from './Options'
	import EndPage from './EndPage'
	
	export default{
		components: {
			Section,
			Demographics,
			GeneralInformation,
			Income,
			PersonalDeductions,
			BusinessDeductions,
			RentalIncomeAndActivity,
			Options,
			EndPage
		},
		data: () => ({
			showConfirmation: false,
			submitConfirmation: false,
			tempSaveConfirmation: false,
			isLoading: false,
			valid: true,
			snackbar: false,
			snackbarMessage: ''
		}),
		computed: {
			...mapState({
				activeSection: state => state.activeSection,
				hasExistingTaxYear: state => state.hasExistingTaxYear,
				isReturningClient: state => state.isReturningClient
			}),
			...mapGetters([
				'steps'
			]),
			cardHeight(){
				switch (this.$vuetify.breakpoint.name) {
					case 'xs': return '500px'
					case 'sm': return '550px'
					case 'md': return '600px'
					case 'lg': return '600px'
					case 'xl': return '800px'
				}
			}
		},
		methods: {
			async confirmAction(action){
				if(this.$refs.section.$refs[this.activeSection].validate()){
					if(this.activeSection == 'Demographics'){ //check if tax payer has an existing data for the tax year selected
						this.isLoading = true
						await this.$store.dispatch('checkExistingTaxYear', {
							ssn: this.$refs.section.demographics.ssn,
							dob: this.$refs.section.demographics.tp_dob,
							year: this.$refs.section.demographics.year,
						})
						this.isLoading = false
						if(this.hasExistingTaxYear){
							this.snackbarMessage = `You already completed to answer the questionnaire for the year ${this.$refs.section.demographics.year}`
							this.snackbar = true
						}
						else{
							this.moveStep(action)
						}
					}
					else{
						if(this.steps.findIndex(x => x.status == 'active') + 1 == this.steps.length-1 && action == 'next'){
							this.showConfirmation = true
						}
						else if(action == 'submit'){
							this.submitConfirmation = true
						}
						else{
							this.moveStep(action)
						}
					}
				}
				else{
					this.snackbarMessage = 'Please complete the form before you proceed.'
					this.snackbar = true
				}
			},
			moveStep(action){
				this.showConfirmation = false
				this.$store.dispatch('moveStep', action)
			},
			async submitSurvey(isCompleted){ //set isCompleted to false for temporary save to allow users to continue answering later
				this.submitConfirmation = false
				this.isLoading = true
				await this.$store.dispatch('submitSurvey', isCompleted)
				this.isLoading = false
			},
			async temporarySave(){
				this.tempSaveConfirmation = false
				this.$refs.section.storeForm()
				await this.submitSurvey(false)
				this.$store.dispatch('resetState')
				await this.$store.dispatch('getPricing', true)
				this.$router.push('/')
			}
		}
	}
</script>