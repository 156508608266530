<template>
	<v-dialog
	  v-model="dialog"
	  persistent :overlay="true"
	  max-width="400px"
	  transition="dialog-transition"
	>
		<v-container class="pa-0">
			<v-card>
				<v-card-text>
					<slot name="message"></slot>
				</v-card-text>
				<v-card-actions>
					<slot name="action"></slot>
				</v-card-actions>
			</v-card>
		</v-container>
	</v-dialog>
</template>
<script>
	export default{
		data: () => ({
			dialog: true
		})
	}
</script>