<template>
	<v-container fluid>
		<v-form ref="RentalIncomeAndActivity">
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">1. How many properties did you operate as rental properties at any time during the tax year?</div>
				</v-col>
				<v-col cols="12">
					<v-text-field label="Number of Properties" outlined background-color="white" v-model="rental.q1" :rules="requiredRule" v-on:keypress="numberOnly"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">2. Did you use any of the properties for personal use during the year?</div>
				</v-col>
				<v-col cols="12">
					<v-radio-group row v-model="rental.q2" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
				<template v-if="rental.q2 == 1">
					<v-row class="mb-8" v-for="(val, index) in rental.q2_1" :key="`${index}-q2`">
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Type of Property" outlined background-color="white" v-model="rental.q2_1[index].property" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Days Rented" outlined background-color="white" v-model="rental.q2_1[index].days_rented" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Days of Personal Use" outlined background-color="white" v-model="rental.q2_1[index].days_of_personal_use" :rules="requiredRule"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field label="Rent Received" prefix="$" outlined background-color="white" append-outer-icon="mdi-plus" v-model="rental.q2_1[index].rent_received" @click:append-outer="addField('q2_1')" v-if="rental.q2_1.length == index+1" :rules="requiredRule"></v-text-field>
							<v-text-field label="Rent Received" prefix="$" outlined background-color="white" append-outer-icon="mdi-minus" v-model="rental.q2_1[index].rent_received" @click:append-outer="removeField('q2_1', index)" v-else :rules="requiredRule"></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">3. What are the property addresses for each property?</div>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-for="(val, index) in rental.q3" :key="`${index}-q3`">
					<v-text-field label="Address" outlined background-color="white" append-outer-icon="mdi-plus" @click:append-outer="addField('q3')" v-model="rental.q3[index].address" v-if="rental.q3.length == index+1" :rules="requiredRule"></v-text-field>
					<v-text-field label="Address" outlined background-color="white" append-outer-icon="mdi-minus" @click:append-outer="removeField('q3', index)" v-model="rental.q3[index].address" v-else :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">4. Please list all expenses associated with the property.</div>
				</v-col>
				<v-col cols="12" sm="12" md="6">
					<v-radio-group row v-model="rental.q4" :rules="requiredRule">
						<v-radio
							label="Yes"
							value="1"
						></v-radio>
						<v-radio
							label="No"
							value="0"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<template v-if="rental.q4 == '1'" v-for="(expenses, idx) in rental.q4_expenses">
				<v-row v-for="(item, index) in expenses" :key="`${idx}-${index}-q4`">
					<v-col cols="4">
						<v-text-field
							outlined
							background-color="white"
							v-model="rental.q4_expenses[idx][index].expense"
							:disabled="!rental.q4_expenses[idx][index].hasOwnProperty('is_editable')"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<v-text-field
							:prefix="rental.q4_expenses[idx][index].expense == 'Auto and travel (miles)' ? '' : '$'"
							:label="rental.q4_expenses[idx][index].expense == 'Auto and travel (miles)' ? 'Miles' : 'Amount'"
							outlined
							background-color="white"
							v-model="rental.q4_expenses[idx][index].amount"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<span v-if="index == 0" class="mb-0">Has supporting documents?</span>
						<v-radio-group row v-model="rental.q4_expenses[idx][index].has_supporting_docs" :class="{ 'mt-0': index == 0 }">
							<v-radio
								label="Yes"
								value="1"
							></v-radio>
							<v-radio
								label="No"
								value="0"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row :key="`${idx}-q4-action`">
					<v-col cols="12">
						<v-btn color="primary" text @click="addExpenseForm" v-if="rental.q4_expenses.length == idx+1"><v-icon>mdi-plus</v-icon> Add Expense Form</v-btn>
						<v-btn color="primary" text @click="removeExpenseForm(idx)" v-else><v-icon>mdi-minus</v-icon> Remove Expense Form</v-btn>
					</v-col>
				</v-row>
				<v-divider v-if="rental.q4_expenses.length > 1" class="mb-5" :key="`${idx}-div`"></v-divider>
			</template>
		</v-form>
	</v-container>
</template>
<script>
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			rental: {
				q1: '',
				q2: '',
				q2_1: [
					{
						property: '',
						days_rented: '',
						days_of_personal_use: '',
						rent_received: ''
					}
				],
				q3: [
					{
						address: ''
					}
				],
				q4: '',
				q4_expenses: [
					[
						{
							expense: 'Advertising',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Auto and travel (miles)',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Cleaning and maintenance',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Commissions',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Insurance',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Legal and other professional fees',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Management fees',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Mortgage interest paid to banks',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Other interest',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Repairs',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Supplies',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Utilities',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Other (describe)',
							amount: '',
							has_supporting_docs: '',
							is_editable: true
						}
					]
				]
			},
			requiredRule: [v => !!v || 'Required']
		}),
		computed: {
			...mapState({
				isReturningClient: state => state.isReturningClient
			})
		},
		watch:{
			'rental.q1'(noOfProperties){
				let active = noOfProperties != '' ? true : false

				this.$store.dispatch('setPayment', {
					section: 7,
					question: 1,
					active: active,
					noOfProperties: noOfProperties
				})
			},
			'rental.q4_expenses': {
				deep: true,
				handler(){
					let documents = []
					for(let i = 0; i < this.rental.q4_expenses.length; i++){
						let hasSuppDocs = this.rental.q4_expenses[i].filter(x => x.has_supporting_docs == '1')
						let expenseList = hasSuppDocs.map(x => x['expense'])
						for(let j = 0; expenseList.length > j; j++){
							if(!documents.includes(expenseList[j])){
								documents = documents.concat(expenseList[j])
							}
						}
					}
					this.$store.dispatch('setUploadList', {
						id: 19,
						document: `${documents.join(',')} supporting documents`,
						active: documents.length > 0 ? true : false
					})
				}
			}
		},
		methods: {
			addField(field){
				this.rental[field].push({})
			},
			removeField(field, index){
				this.rental[field].splice(index, 1)
			},
			storeForm(){
				this.$store.dispatch('setRentalForm', this.rental)
			},
			addExpenseForm(){
				this.rental.q4_expenses.push([
						{
							expense: 'Advertising',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Auto and travel (miles)',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Cleaning and maintenance',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Commissions',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Insurance',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Legal and other professional fees',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Management fees',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Mortgage interest paid to banks',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Other interest',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Repairs',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Supplies',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Utilities',
							amount: '',
							has_supporting_docs: ''
						},
						{
							expense: 'Other (describe)',
							amount: '',
							has_supporting_docs: '',
							is_editable: true
						}
					])
			},
			removeExpenseForm(index){
				this.rental.q4_expenses.splice(index, 1)
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData' && this.$store.state.rentalModule.rentalForm.length != 0){
				this.rental = this.$store.state.rentalModule.rentalForm
				if(this.rental.q2_1 == null){
					this.rental.q2_1 =  [
						{
							property: '',
							days_rented: '',
							days_of_personal_use: '',
							rent_received: ''
						}
					]
				}
			}
		},
		deactivated(){
			this.storeForm()
		}
	}
</script>