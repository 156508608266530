export default {
	state: {
		demographicsForm: []
	},
	mutations: {
		SET_DEMOGRAPHICS_FORM(state, demographics){
			state.demographicsForm = demographics
		}
	},
	getters: {

	},
	actions: {
		setDemographicsForm({commit}, demographics){
			commit('SET_DEMOGRAPHICS_FORM', demographics)
		}
	}
}