export default {
	state: {
		businessDeductionsForm: []
	},
	mutations: {
		SET_BUSINESS_DEDUCTIONS_FORM(state, businessDeductions){
			state.businessDeductionsForm = businessDeductions
		}
	},
	getters: {

	},
	actions: {
		setBusinessDeductionsForm({commit}, businessDeductions){
			commit('SET_BUSINESS_DEDUCTIONS_FORM', businessDeductions)
		}
	}
}