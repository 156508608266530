export default {
	state: {
		rentalForm: []
	},
	mutations: {
		SET_RENTAL_FORM(state, rental){
			state.rentalForm = rental
		}
	},
	getters: {

	},
	actions: {
		setRentalForm({commit}, rental){
			commit('SET_RENTAL_FORM', rental)
		}
	}
}