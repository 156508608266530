<template>
	<v-container fluid>
		<v-snackbar
			v-model="snackbar"
			:timeout="5000"
			top>
			{{ snackbarMessage }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="primary"
					text
					v-bind="attrs"
					@click="snackbar = false">
	          		Close
	        	</v-btn>
	    	</template>
    	</v-snackbar>
		<v-form ref="Demographics">
			<v-row>
				<v-col cols="12" sm="12" md="5">
					<v-text-field label="First Name" outlined background-color="white" v-model="demographics.first_name" :rules="requiredRule"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="5">
					<v-text-field label="Last Name" outlined background-color="white" v-model="demographics.last_name" :rules="requiredRule"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="2">
					<v-text-field label="Middle Initial" outlined background-color="white" v-model="demographics.middle_initial"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12" md="6">
					<v-text-field label="SSN" outlined background-color="white" v-model="demographics.ssn" :rules="requiredRule" maxlength="11"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="6">
					<v-dialog
						ref="dialog"
						v-model="datepickerDialog"
						:return-value.sync="tpDob"
						persistent
						width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field 
								label="Date of Birth" 
								outlined background-color="white" 
								v-model="tpDob"
								v-bind="attrs"
								v-on="on"
								append-icon="mdi-calendar"
								:rules="requiredRule"
								readonly></v-text-field>
						</template>
						<v-date-picker
							v-model="demographics.tp_dob"
							scrollable>
							<v-spacer></v-spacer>
							<v-tooltip top>
								<template v-slot:activator="{on, attrs}">
									<div v-bind="attrs" v-on="on">
										<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
									</div>
								</template>
								<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
								</span>
							</v-tooltip>
							<v-spacer></v-spacer>
							<v-btn 
								text	
								color="primary"
								@click="datepickerDialog = false">
								Cancel
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="$refs.dialog.save(tpDob)">
								OK
							</v-btn>
						</v-date-picker>
					</v-dialog>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12" md="6">
					<v-text-field label="Email Address" outlined background-color="white" v-model="demographics.email" :rules="emailRules"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="6">
					<v-text-field label="Phone Number" outlined background-color="white" v-model="demographics.phone_number" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="mb-8">
				<v-col cols="12">
					<div class="text-subtitle-1">What is the intended filing status for tax year being prepared?</div>
				</v-col>
				<v-col cols="12">
					<v-select
					  :items="filingStatus"
					  item-text="text"
					  item-value="id"
					  v-model="demographics.filing_status"
					  label="Filing Status"
					  outlined background-color="white"
					  :rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12" md="12">
					<v-text-field label="Street Address or PO Box" outlined background-color="white" v-model="demographics.street_address" :rules="requiredRule"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="4">
					<v-text-field label="City" outlined background-color="white" v-model="demographics.city" :rules="requiredRule"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="4">
					<v-text-field label="State" outlined background-color="white" v-model="demographics.state" :rules="requiredRule"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="4">
					<v-text-field label="Zip Code" outlined background-color="white" v-model="demographics.zip_code" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-text-field label="Occupation" outlined background-color="white" v-model="demographics.occupation" :rules="requiredRule"></v-text-field>
				</v-col>
			</v-row>
			<template v-if="demographics.filing_status == 2">
				<v-row class="mb-8">
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Spouse First Name" outlined background-color="white" v-model="demographics.spouse_first_name" :rules="requiredRule"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Spouse Last Name" outlined background-color="white" v-model="demographics.spouse_last_name" :rules="requiredRule"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Spouse SSN" outlined background-color="white" v-model="demographics.spouse_ssn" :rules="requiredRule" maxlength="11"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-dialog
							ref="spouseDialog"
							v-model="spouseDatepickerDialog"
							:return-value.sync="spouseDob"
							persistent
							width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field 
									label="Date of Birth" 
									outlined background-color="white" 
									v-model="spouseDob"
									v-bind="attrs"
									v-on="on"
									append-icon="mdi-calendar"
									:rules="requiredRule"
									readonly></v-text-field>
							</template>
							<v-date-picker
								v-model="demographics.spouse_dob"
								scrollable>
								<v-spacer></v-spacer>
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
										</div>
									</template>
									<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
									</span>
								</v-tooltip>
								<v-spacer></v-spacer>
								<v-btn 
									text	
									color="primary"
									@click="spouseDatepickerDialog = false">
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.spouseDialog.save(spouseDob)">
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Spouse Email Address" outlined background-color="white" v-model="demographics.spouse_email" :rules="emailRules"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Spouse Phone Number" outlined background-color="white" v-model="demographics.spouse_phone_number" :rules="requiredRule"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="12">
						<v-text-field label="Spouse Occupation" outlined background-color="white" v-model="demographics.spouse_occupation" :rules="requiredRule"></v-text-field>
					</v-col>
				</v-row>
				<v-row v-for="(val, index) in demographics.dependents" :key="`${index}-dep`" class="mb-8">
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Dependent First Name" outlined background-color="white" v-model="demographics.dependents[index].first_name"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Dependent Last Name" outlined background-color="white" v-model="demographics.dependents[index].last_name" append-outer-icon="mdi-plus" @click:append-outer="addDependents" v-if="demographics.dependents.length == index+1"></v-text-field>
						<v-text-field label="Dependent Last Name" outlined background-color="white" v-model="demographics.dependents[index].last_name" append-outer-icon="mdi-minus" @click:append-outer="removeDependents(index)" v-else></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field label="Dependent SSN" outlined background-color="white" v-model="demographics.dependents[index].ssn" maxlength="11"></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-dialog
							ref="dependentDialog"
							v-model="demographics.dependents[index].dialog"
							:return-value.sync="dependentDob[index]"
							persistent
							width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field 
									label="Date of Birth" 
									outlined background-color="white" 
									v-model="dependentDob[index]"
									v-bind="attrs"
									v-on="on"
									append-icon="mdi-calendar"
									readonly></v-text-field>
							</template>
							<v-date-picker
								v-model="demographics.dependents[index].dob"
								scrollable>
								<v-spacer></v-spacer>
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<div v-bind="attrs" v-on="on">
											<v-icon class="blue--text">mdi-information-outline</v-icon><span class="text-caption blue--text">NOTE</span>
										</div>
									</template>
									<span>You may click on the month and year between the left and right arrows to easily navigate with the date picker
									</span>
								</v-tooltip>
								<v-spacer></v-spacer>
								<v-btn 
									text	
									color="primary"
									@click="demographics.dependents[index].dialog = false">
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.dependentDialog[index].save(dependentDob[index])">
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
				</v-row>
			</template>
			<v-row>
				<v-col cols="12">
					<v-select
						:items="yearRange"
						v-model="demographics.year"
						label="Tax Year"
						outlined background-color="white"
						:rules="requiredRule"
					></v-select>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<script>
	import dayjs from 'dayjs'
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			snackbar: false,
			snackbarMessage: '',
			demographics: {
				middle_initial: '',
				dependents: [
					{
						first_name: '',
						last_name: '',
						ssn: '',
						dob: ''
					}
				]
			},
			filingStatus: [
				{
					id: 1,
					text: 'Single'
				},
				{
					id: 2,
					text: 'Married Joint'
				},
				{
					id: 3,
					text: 'Married Separately'
				},
				{
					id: 4,
					text: 'Head of Household'
				},
				{
					id: 5,
					text: 'Qualified Widow(er)'
				},
				{
					id: 6,
					text: 'I don’t know. Please review my info and advise me'
				}
			],
			datepickerDialog: false,
			spouseDatepickerDialog: false,
			tpDob: '',
			spouseDob: '',
			dependentDob: [],
			requiredRule: [v => !!v || 'Required'],
			emailRules: [
				v => !!v || 'Required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      		],
		}),
		watch: {
			'demographics.ssn'(val){
				if(val.length == 3 || val.length == 6){
					this.demographics.ssn = `${val}-`
				}
			},
			'demographics.spouse_ssn'(val){
				if(val.length == 3 || val.length == 6){
					this.demographics.spouse_ssn = `${val}-`
				}
			},
			'demographics.dependents': {
				deep: true,
				handler(val){
					for(let i = 0; i < val.length; i++){
						if(val[i].ssn.length == 3 || val[i].ssn.length == 6){
							this.demographics.dependents[i].ssn = `${val[i].ssn}-`
						}

						if(val[i].dob != ''){
							this.dependentDob[i] = dayjs(val[i].dob).format('MM/DD/YYYY')
						}
					}
				}
			},
			'demographics.tp_dob'(date){
				this.tpDob = dayjs(date).format('MM/DD/YYYY')
			},
			'demographics.spouse_dob'(date){
				this.spouseDob = dayjs(date).format('MM/DD/YYYY')
			}
		},
		computed: {
			...mapState({
				isReturningClient: state => state.isReturningClient
			}),
			yearRange(){
				let range = []
				let currentYear = new Date().getFullYear()
				for(let i = 0; i < 10; i++){
					if(i == 0){
						range.push(currentYear)
					}
					else{
						range.push(currentYear--)
					}
				}
				return range
			}
		},
		methods: {
			addDependents(){
				this.demographics.dependents.push({
					first_name: '',
					last_name: '',
					ssn: '',
					dob: ''
				})
			},
			removeDependents(index){
				this.demographics.dependents.splice(index, 1)
			}
		},
		mounted(){
			if(this.isReturningClient == 'hasExistingData'){
				this.demographics = this.$store.state.demographicsModule.demographicsForm
				if(!this.demographics.hasOwnProperty('dependents')){
					this.demographics.dependents = [{
						first_name: '',
						last_name: '',
						ssn: '',
						dob: ''
					}]
				}
			}
			else if(this.isReturningClient == 'noExistingData'){
				this.snackbarMessage = 'No Tax Payer Information Found. Please input your information.'
				this.snackbar = true
			}
		},
		deactivated(){
			this.demographics.dependents.map(x => {
				delete x.dialog
			})
			this.$store.dispatch('setDemographicsForm', this.demographics)
		}
	}
</script>