export default {
	state: {
		personalDeductionsForm: []
	},
	mutations: {
		SET_PERSONAL_DEDUCTIONS_FORM(state, personalDeductions){
			state.personalDeductionsForm = personalDeductions
		}
	},
	getters: {

	},
	actions: {
		setPersonalDeductionsForm({commit}, personalDeductions){
			commit('SET_PERSONAL_DEDUCTIONS_FORM', personalDeductions)
		}
	}
}